import { Box } from '@mui/material';
import React from 'react';
import Typography from 'widgets/Typography/Typography';
import { formatTimeInMinSecs } from 'utils/formatTimeInMinSecs';
import {
  CoachingSessionReport,
  TranscriptDataArray,
} from 'types/VideoPreview-types';

interface TranscriptComponentProps {
  Data: TranscriptDataArray[] | Array<Array<TranscriptDataArray>>;
  ProcessDataListForParticipantsVersion2?: CoachingSessionReport[];
  isAssessment?: boolean;
}

const TranscriptComponent: React.FC<TranscriptComponentProps> = ({
  Data,
  ProcessDataListForParticipantsVersion2,
  isAssessment,
}) => {
  const normalizedData: TranscriptDataArray[] = Array.isArray(Data[0])
    ? (Data as Array<Array<TranscriptDataArray>>).flat()
    : (Data as TranscriptDataArray[]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '85vh',
        p: 3,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Typography
        label='Transcript'
        sx={{ color: '#33344B', fontSize: '16px', fontWeight: 600 }}
      />
      <Typography
        label='Overall'
        sx={{ color: '#605E5E', fontSize: '11px', fontWeight: 400 }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '20vh',
          mt: 2,
          mb: 2,
          maxHeight: '100%',
          overflow: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {normalizedData.length === 0 ? (
          <Typography
            label='Please reprocess session to get Transcript'
            sx={{ color: '#000', fontSize: '14px', fontWeight: 500 }}
          ></Typography>
        ) : (
          <>
            {isAssessment ? (
              <Box>
                {Object.entries(normalizedData).map(([key, items]) => {
                  let matchingPrompt;

                  if (ProcessDataListForParticipantsVersion2) {
                    matchingPrompt =
                      ProcessDataListForParticipantsVersion2[0].prompt_sections.find(
                        (section) =>
                          section.coaching_session_reports_id === Number(key)
                      );
                  }
                  return (
                    <Box key={key} sx={{ mb: 4 }}>
                      <Typography
                        label=''
                        sx={{
                          color: '#000',
                          fontSize: '15px',
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        {matchingPrompt?.prompt_question ||
                          'Question not found'}
                      </Typography>
                      {Array.isArray(items) &&
                        items.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 3,
                            }}
                          >
                            <Typography
                              label=''
                              sx={{
                                color: '#605E5E',
                                backgroundColor: '#E0E0E09E',
                                padding: '2px 6px',
                                borderRadius: '35px',
                                marginRight: '10px',
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                              }}
                            >
                              {formatTimeInMinSecs(
                                Math.floor(item.start_time_stamp)
                              )}
                            </Typography>
                            <Typography
                              label=''
                              sx={{
                                color: '#33344B',
                                fontSize: '14px',
                                fontWeight: 400,
                              }}
                            >
                              <pre>{item.transcription}</pre>
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              normalizedData.map((item, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
                >
                  <Typography
                    label=''
                    sx={{
                      color: '#605E5E',
                      backgroundColor: '#E0E0E09E',
                      padding: '2px 6px',
                      borderRadius: '35px',
                      marginRight: '10px',
                      whiteSpace: 'nowrap',
                      fontSize: '12px',
                    }}
                  >
                    {formatTimeInMinSecs(Math.floor(item.start_time_stamp))}
                  </Typography>
                  <Typography
                    label=''
                    sx={{
                      color: '#33344B',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    <pre>{item.transcription}</pre>
                  </Typography>
                </Box>
              ))
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TranscriptComponent;
