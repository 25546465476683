import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SettingsIcon from 'icons/SettingsIcon';
import React, { useRef, useState, useEffect } from 'react';
import Typography from 'widgets/Typography/Typography';
import PatientVideoRecorder from './PatientVideoRecorder';
import VideoQuestionaire from './VideoQuestionaire';
import {
  DeviceInfo,
  QuestionnaireData,
  UploadQuestionnairePayload,
} from 'types/TeleHealth/PatientWorkflow-types';
import axios from 'axios';
import Loader from 'widgets/Loader/Loader';
import { PatientWorkFlowStepComponentList } from 'constants/PatientWorkflow';
import store, { useAppDispatch } from 'redux/store';
import {
  setIsLastVideo,
  setRelatedToId,
} from 'redux/slices/MyAssessment-slice';
import {
  ReactMediaRecorderProps,
  ReactMediaRecorderRenderProps,
  useReactMediaRecorder,
} from 'react-media-recorder';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';
import PatientVideoIosRecorder from './PatientVideoIosRecorder';
import Switch from '@mui/material/Switch';
import RecordCamera from 'icons/RecordCamera';
import { styled } from '@mui/material/styles';
import env from 'envConfig';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(22px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M17 10.5V7c0-.8-.7-1.5-1.5-1.5h-11C4.7 5.5 4 6.2 4 7v10c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5v-3.5l4 4v-11l-4 4z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
      },
    },
    '&:not(.Mui-checked)': {
      transform: 'translateX(6px)',
      '& .MuiSwitch-thumb:before': {
        // Video Camera Slash Icon
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M21 16.5l-4-4V7c0-.8-.7-1.5-1.5-1.5h-11c-.2 0-.3 0-.5.1l11.8 11.8h.2c.8 0 1.5-.7 1.5-1.5v-3.5l4 4zM3.7 4.3L2.3 5.7 6.6 10H6.5c-.8 0-1.5.7-1.5 1.5v5c0 .8.7 1.5 1.5 1.5h11c.3 0 .6-.1.9-.2l2.1 2.1 1.4-1.4-18-18z"/></svg>')`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

function QuestionnaireComponent({
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  apiKey,
  Questionnaire,
  patientID,
  providerID,
  sessionId,
  customfilename,
}: {
  goToPatientWorkflowStep: Function;
  videoDevices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  selectedVideoDevice: CameraDetails;
  selectedAudioDevice: MicrophoneDetails;
  setSelectedVideoDevice: Function;
  setSelectedAudioDevice: Function;
  apiKey: string;
  Questionnaire: Array<QuestionnaireData>;
  patientID: string;
  providerID: number;
  sessionId: number;
  customfilename: string;
}) {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const uploadQueue = useRef<
    Array<{
      payload: UploadQuestionnairePayload;
      blob?: Blob;
      duration: number;
      TextAnswer?: string;
    }>
  >([]);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');
  const [anchorElRecorderSettingsDialog, setAnchorElRecorderSettingsDialog] =
    useState<null | HTMLElement>(null);
  const [isFirstUpload, setIsFirstUpload] = useState(true);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isReUpload, setIsReUpload] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<boolean[]>(
    Array(Questionnaire.length).fill(false)
  );
  const [isCameraEnabled, setIsCameraEnabled] = useState(1);

  const {
    status,
    startRecording,
    stopRecording,
    previewStream,
    clearBlobUrl,
    mediaBlobUrl,
  }: ReactMediaRecorderRenderProps = useReactMediaRecorder({
    audio: true,
    video: isCameraEnabled === 0,
  });

  const [isPlayed, setIsPlayer] = useState(false);

  useEffect(() => {
    const index = sessionStorage.getItem('currentQuestion');
    const RelatedToId = sessionStorage.getItem('RelatedToId');
    if (index && RelatedToId) {
      setCurrentQuestionIndex(Number(index) + 1);
      setIsFirstUpload(false);
      dispatch(setRelatedToId(RelatedToId));
    }
  }, []);

  useEffect(() => {
    if (uploadStatus[currentQuestionIndex]) {
      setIsUploaded(true);
      setIsReUpload(true);
    } else {
      setIsUploaded(false);
      setIsReUpload(false);
    }
  }, [currentQuestionIndex]);

  const uploadVideo = async (
    payload: UploadQuestionnairePayload,
    blob: Blob,
    duration: number
  ) => {
    try {
      const detailsString = encodeURIComponent(JSON.stringify(payload.Details));
      let apiUrl;
      let relatedToId =
        store.getState().api.sectionUploadStatusReducer.related_to;

      apiUrl = `${env.REACT_APP_API_BASE_URL}/UploadSectionVideo?session_type_id=${sessionId}&section_id=${payload.id}&title=${customfilename}&output_period_msec=3072&video_analysis=false&text_analysis=true&dynamic_prediction=true&duration_in_msec=${payload.duration_in_msec}&is_skipped=false&language=English&candidate_details=${detailsString}&last_section=${payload.last_section}`;

      if (relatedToId !== -1) {
        apiUrl += `&related_to=${relatedToId}`;
      }

      const formData = new FormData();
      formData.append(
        'files',
        blob,
        isCameraEnabled === 1
          ? `${customfilename}.mp3`
          : `${customfilename}.mp4`
      );

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'multipart/form-data',
        },
      });

      sessionStorage.setItem(
        'currentQuestion',
        JSON.stringify(currentQuestionIndex)
      );

      const newRelatedToId = response.data.Job_Id;

      if (isFirstUpload && relatedToId === -1) {
        dispatch(setRelatedToId(newRelatedToId));
        setIsFirstUpload(false);
        sessionStorage.setItem('RelatedToId', JSON.stringify(newRelatedToId));
      }

      setUploadStatus((prevStatus) => {
        const updatedStatus = [...prevStatus];
        updatedStatus[currentQuestionIndex] = true;
        return updatedStatus;
      });

      if (currentQuestionIndex === Questionnaire.length - 1) {
        setIsLoading(false);
        goToPatientWorkflowStep(PatientWorkFlowStepComponentList.Confirmation);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleRecordingComplete = async (
    duration: number,
    isPrev: boolean,
    blobUrl?: Blob | MediaSource | string | null,
    TextAnswer?: string
  ) => {
    if (!blobUrl && isCameraEnabled !== 2) return;

    if (currentQuestionIndex === Questionnaire.length - 1) {
      dispatch(setIsLastVideo(true));
      setIsLoading(true);
    }

    const currentQuestion = Questionnaire[currentQuestionIndex];

    const uploadPayload = {
      id: currentQuestion.id,
      videoDescription: currentQuestion.question,
      duration_in_msec: duration * 1000,
      Details: {
        patientID: patientID,
        ProviderID: providerID,
      },
      last_section: store.getState().api.sectionUploadStatusReducer.isLastVideo,
    };

    if (isCameraEnabled !== 2) {
      let blob: Blob;
      if (typeof blobUrl === 'string') {
        blob = await fetch(blobUrl).then((response) => response.blob());
      } else if (blobUrl instanceof Blob || blobUrl instanceof MediaSource) {
        blob = blobUrl as Blob;
      } else {
        return;
      }

      uploadQueue.current.push({
        payload: uploadPayload,
        blob,
        duration,
      });
    } else {
      if (TextAnswer && TextAnswer.trim() !== '') {
        uploadQueue.current.push({
          payload: uploadPayload,
          TextAnswer,
          duration,
        });
      }
    }

    processUploadQueue();

    clearBlobUrl();

    if (isPrev) {
      setCurrentQuestionIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

        return newIndex;
      });
    } else {
      setCurrentQuestionIndex((prevIndex) => {
        if (prevIndex < Questionnaire.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    }
  };

  const processUploadQueue = async () => {
    if (isUploading || uploadQueue.current.length === 0) return;

    setIsUploading(true);

    try {
      while (uploadQueue.current.length > 0) {
        const { payload, blob, duration, TextAnswer } = uploadQueue.current[0];
        if (blob) {
          await uploadVideo(payload, blob, duration);
        } else if (TextAnswer && TextAnswer.trim() !== '') {
          await uploadText(payload, TextAnswer);
        }

        uploadQueue.current.shift();
      }
    } finally {
      setIsUploading(false);
    }
  };

  const uploadText = async (
    payload: UploadQuestionnairePayload,
    TextAnswer: string
  ) => {
    try {
      const detailsString = encodeURIComponent(JSON.stringify(payload.Details));
      let apiUrl;
      let relatedToId =
        store.getState().api.sectionUploadStatusReducer.related_to;

      apiUrl = `${env.REACT_APP_API_BASE_URL}/UploadSectionText?session_type_id=${sessionId}&section_id=${payload.id}&title=${customfilename}&language=English&candidate_details=${detailsString}&last_section=${payload.last_section}`;

      if (relatedToId !== -1) {
        apiUrl += `&related_to=${relatedToId}`;
      }

      const formData = new FormData();
      formData.append('text_input', TextAnswer);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
      });

      sessionStorage.setItem(
        'currentQuestion',
        JSON.stringify(currentQuestionIndex)
      );

      const newRelatedToId = response.data.Job_Id;

      if (isFirstUpload && relatedToId === -1) {
        dispatch(setRelatedToId(newRelatedToId));
        setIsFirstUpload(false);
        sessionStorage.setItem('RelatedToId', JSON.stringify(newRelatedToId));
      }

      setUploadStatus((prevStatus) => {
        const updatedStatus = [...prevStatus];
        updatedStatus[currentQuestionIndex] = true;
        return updatedStatus;
      });

      if (currentQuestionIndex === Questionnaire.length - 1) {
        setIsLoading(false);
        goToPatientWorkflowStep(PatientWorkFlowStepComponentList.Confirmation);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClickRecorderSettingsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElRecorderSettingsDialog(event.currentTarget);
  };
  const handleCloseRecorderSettingsDialog = () => {
    setAnchorElRecorderSettingsDialog(null);
  };

  return (
    <Box sx={{ minHeight: '97.5vh' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            m: isMobileOrTablet ? 1 : 5,
            mt: isMobileOrTablet ? 1 : 5,
            mb: isMobileOrTablet ? 1 : 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {Questionnaire.length > 0 && Questionnaire[currentQuestionIndex] ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: isSmallDevice ? 'column' : 'row',
                }}
              >
                <Typography
                  label='To provide personalized care for you, we kindly ask you to watch the videos and record your responses.'
                  sx={{ color: '#000000', fontSize: '20px', fontWeight: 500 }}
                />

                {/* <IconButton
                  onClick={handleClickRecorderSettingsDialog}
                  disabled={status === 'recording'}
                  sx={{ opacity: status === 'recording' ? 0.4 : 1 }}
                >
                  <SettingsIcon />
                </IconButton> */}
              </Box>

              <Grid
                container
                alignItems='stretch'
                spacing={1}
                sx={{
                  mt: 1,
                }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <VideoQuestionaire
                    question={Questionnaire[currentQuestionIndex]}
                    currentQuestionIndex={currentQuestionIndex}
                    status={status}
                    isIOS={isIOS}
                    setIsPlayer={setIsPlayer}
                    isPlayed={isPlayed}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {isIOS ? (
                    <PatientVideoIosRecorder
                      onRecordingComplete={handleRecordingComplete}
                      setCurrentQuestionIndex={setCurrentQuestionIndex}
                      currentQuestionIndex={currentQuestionIndex}
                      Questionnaire={Questionnaire}
                      selectedVideoDevice={selectedVideoDevice}
                      selectedAudioDevice={selectedAudioDevice}
                      status={status}
                      mediaBlobUrl={mediaBlobUrl!!}
                      isReUpload={isReUpload}
                      isUploaded={isUploaded}
                      isCameraEnabled={isCameraEnabled}
                      setIsCameraEnabled={setIsCameraEnabled}
                    />
                  ) : (
                    <>
                      <PatientVideoRecorder
                        onRecordingComplete={handleRecordingComplete}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        currentQuestionIndex={currentQuestionIndex}
                        Questionnaire={Questionnaire}
                        selectedVideoDevice={selectedVideoDevice}
                        selectedAudioDevice={selectedAudioDevice}
                        status={status}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        mediaBlobUrl={mediaBlobUrl!!}
                        isReUpload={isReUpload}
                        isUploaded={isUploaded}
                        clearBlobUrl={clearBlobUrl}
                        isCameraEnabled={isCameraEnabled}
                        isPlayed={isPlayed}
                        setIsCameraEnabled={setIsCameraEnabled}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      )}
      {/* <Menu
        id='questionaire-component-recorderSettings-menu'
        anchorEl={anchorElRecorderSettingsDialog}
        open={Boolean(anchorElRecorderSettingsDialog)}
        onClose={handleCloseRecorderSettingsDialog}
        PaperProps={{
          style: {
            width: isMobileOrTablet ? '100%' : '30%',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
          <MenuItem>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <RecordCamera />
                <Typography
                  label='camera'
                  sx={{ fontSize: '14px', fontWeight: 500 }}
                />
              </Box>
              <MaterialUISwitch
                checked={isCameraEnabled}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </MenuItem>
        </Box>
      </Menu> */}
    </Box>
  );
}

export default QuestionnaireComponent;
