/** @format */

import * as React from 'react';
import Typography from 'widgets/Typography/Typography';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import './videoDisplayCard.css';
import moment from 'moment';
import { lazy, Suspense } from 'react';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import DeleteIcon from 'icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { Toast } from 'widgets/Toast/Toast';
import { truncateFilename } from 'utils/truncateFileNames';
import VariationBadge from 'icons/VariationBadge';
import EngagementBadge from 'icons/EngagementBadge';
import PositivityBadge from 'icons/PositivityBadge';
import EnergyBadge from 'icons/EnergyBadge';
import DisabledBadge from 'icons/DisabledBadge';
import { videoPreviewConstants } from '../../__testData/constants/VideoPreviewAdvance/videoPreviewConstants';

interface VideoDisplayCardProps {
  key: number;
  title: string;
  endTime: string;
  startTime: string;
  engagementScore: number;
  thumbnailImageBytes: string;
  sessionName: string;
  overallEmotion: string;
  metrics_processing_event_history_id: number;
  handleOpenDeleteModal: Function;
  set_metrics_processing_event_history_id: Function;
  handleOpenReprocessVideoModal: Function;
  setReprocessVideoData: Function;
  sessionDetails: { id: number; name: string };
  onClickVideoCard: Function;
  totalAttendees: number;
  error_flag: boolean;
  overall_summary: string;
  process_status: number;
  variation_score: number;
  engagement_score: number;
  positivity_score: number;
  overall_energy: number;
  isPreview?: boolean;
  textAnalysisEnabled: boolean;
  videoAnalysisEnabled: boolean;
}

export default function VideoDisplayCard({
  key,
  title,
  endTime,
  engagementScore,
  thumbnailImageBytes,
  sessionName,
  overallEmotion,
  metrics_processing_event_history_id,
  handleOpenDeleteModal,
  set_metrics_processing_event_history_id,
  handleOpenReprocessVideoModal,
  setReprocessVideoData,
  sessionDetails,
  onClickVideoCard,
  totalAttendees,
  error_flag,
  overall_summary,
  startTime,
  process_status,
  variation_score,
  engagement_score,
  positivity_score,
  overall_energy,
  isPreview,
  textAnalysisEnabled,
  videoAnalysisEnabled,
}: VideoDisplayCardProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isHoveredBox, setIsHoveredBox] = React.useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(isPreview ? false : true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterBox = () => {
    setIsHoveredBox(true);
  };

  const handleMouseLeaveBox = () => {
    setIsHoveredBox(false);
  };

  const IconComponent = lazy(async () => {
    return await import(`icons/Emojis/${overallEmotion}EmojiIcon`).then(
      (module) => ({
        default: module.default,
      })
    );
  });

  const commonTypographyStyle = {
    fontSize: '14px',
  };

  return (
    <>
      {!error_flag ? (
        process_status === 9 ? (
          <Box
            sx={{
              width: 320,
              height: isPreview ? 320 : 'min-content',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              cursor: 'pointer',
              boxShadow: isHoveredBox ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
              padding: 1,
              borderRadius: 2,
              transition: 'box-shadow 1s ease ',
              fontFamily: 'Poppins',
              alignItems: 'flex-start',
            }}
            key={key}
            onClick={() =>
              onClickVideoCard(
                metrics_processing_event_history_id,
                totalAttendees
              )
            }
            onMouseEnter={handleMouseEnterBox}
            onMouseLeave={handleMouseLeaveBox}
          >
            <Box
              className='image-container'
              sx={{
                width: '100%',
                height: '227.079px',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                borderRadius: '10px',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={`data:image/png;base64,${thumbnailImageBytes}`}
                alt={title}
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '10px' }}
              />
              {isHovered && (
                <Box className='buttons'>
                  <Tooltip title={t('gallery.Delete')}>
                    <IconButton
                      id='deleteCard'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenDeleteModal();
                        set_metrics_processing_event_history_id(
                          metrics_processing_event_history_id
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('gallery.Reprocess')}>
                    <IconButton
                      id='reprocessCard'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenReprocessVideoModal();
                        setReprocessVideoData({
                          video_title: title,
                          metrics_processing_events_history_id_list:
                            metrics_processing_event_history_id,
                          sessionDetails: sessionDetails,
                          text_analysis: textAnalysisEnabled,
                          video_analysis: videoAnalysisEnabled,
                        });
                      }}
                    >
                      <SyncOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                minHeight: '200px',
                height: '100%',
                display: 'flex',

                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography
                sx={commonTypographyStyle}
                label={truncateFilename(title.replace(/^\d{29}[_-]?/, ''), 30)}
              />

              {totalAttendees === 0 && (
                <Typography
                  sx={{ ...commonTypographyStyle, color: '#d33022' }}
                  label='gallery.NoSpeakersDetected'
                />
              )}

              {!isPreview && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    gap: 1,
                  }}
                >
                  <Suspense
                    fallback={
                      <CircularProgress
                        style={{ width: '24px', height: '24px' }}
                      />
                    }
                  >
                    <IconComponent />
                  </Suspense>
                  <Typography
                    sx={commonTypographyStyle}
                    label={overallEmotion}
                  />
                  <Box>
                    <Tooltip title={t('dashboard.Engagement')}>
                      <IconButton>
                        {engagement_score >= 75 ? (
                          <EngagementBadge />
                        ) : (
                          <DisabledBadge />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('dashboard.Positivity')}>
                      <IconButton>
                        {positivity_score >= 75 ? (
                          <PositivityBadge />
                        ) : (
                          <DisabledBadge />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('dashboard.Energy')}>
                      <IconButton>
                        {overall_energy >= 75 ? (
                          <EnergyBadge />
                        ) : (
                          <DisabledBadge />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('dashboard.Variation')}>
                      <IconButton>
                        {variation_score >= 75 ? (
                          <VariationBadge />
                        ) : (
                          <DisabledBadge />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.7,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={commonTypographyStyle}
                    label='gallery.EngagementScore'
                  ></Typography>
                  <Typography
                    sx={commonTypographyStyle}
                    label={`:  ${engagementScore}`}
                  >
                    {' '}
                  </Typography>
                </Box>
                <Typography sx={commonTypographyStyle} label={sessionName}>
                  {' '}
                </Typography>
                {!isPreview && (
                  <Typography
                    sx={commonTypographyStyle}
                    label={moment(new Date(`${endTime}.000Z`)).fromNow()}
                  >
                    {' '}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ) : process_status < 9 && process_status !== 13 ? (
          <Box
            sx={{
              width: 320,
              height: 'min-content',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              cursor: 'pointer',
              boxShadow: isHoveredBox ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
              padding: 2,
              borderRadius: 2,
              transition: 'box-shadow 1s ease ',
              fontFamily: 'Poppins',
              marginBottom: '20px',
              alignItems: 'flex-start',
            }}
            key={key}
            onClick={() => {
              Toast(
                'Please wait until the processing of this media is complete and then try again in some time',
                'error'
              );
            }}
            onMouseEnter={handleMouseEnterBox}
            onMouseLeave={handleMouseLeaveBox}
          >
            <Box
              className='image-container'
              sx={{
                width: '100%',
                height: '227.079px',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                borderRadius: '10px',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={`data:image/png;base64,${thumbnailImageBytes}`}
                alt={title}
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '10px' }}
              />
              {isHovered && (
                <Box className='buttons'>
                  <Tooltip title={t('gallery.Delete')}>
                    <IconButton
                      id='deleteCard'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenDeleteModal();
                        set_metrics_processing_event_history_id(
                          metrics_processing_event_history_id
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('gallery.Reprocess')}>
                    <IconButton
                      id='reprocessCard'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenReprocessVideoModal();
                        setReprocessVideoData({
                          video_title: title,
                          metrics_processing_events_history_id_list:
                            metrics_processing_event_history_id,
                          sessionDetails: sessionDetails,
                          text_analysis: textAnalysisEnabled,
                          video_analysis: videoAnalysisEnabled,
                        });
                      }}
                    >
                      <SyncOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                minHeight: '200px',
                height: '100%',
                display: 'flex',

                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                sx={commonTypographyStyle}
                label={truncateFilename(title.replace(/^\d{29}[_-]?/, ''), 30)}
              />

              <Typography
                sx={commonTypographyStyle}
                label='Session in processing'
              />

              <Typography sx={commonTypographyStyle} label=''>
                {sessionName} <br />
                {moment(new Date(`${startTime}.000Z`)).fromNow()}
              </Typography>
            </Box>
          </Box>
        ) : null
      ) : (
        <Box
          sx={{
            width: 320,
            height: 'min-content',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            cursor: 'pointer',
            boxShadow: isHoveredBox ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
            padding: 2,
            borderRadius: 2,
            transition: 'box-shadow 1s ease ',
            fontFamily: 'Poppins',
            marginBottom: '20px',
            alignItems: 'flex-start',
          }}
          key={key}
          onClick={() =>
            onClickVideoCard(
              metrics_processing_event_history_id,
              totalAttendees
            )
          }
          onMouseEnter={handleMouseEnterBox}
          onMouseLeave={handleMouseLeaveBox}
        >
          <Box
            className='image-container'
            sx={{
              width: '100%',
              height: '227.079px',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Poppins',
              borderRadius: '10px',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={`data:image/png;base64,${thumbnailImageBytes}`}
              alt={title}
              width={'100%'}
              height={'100%'}
              style={{ borderRadius: '10px' }}
            />
            {isHovered && (
              <Box className='buttons'>
                <Tooltip title={t('gallery.Delete')}>
                  <IconButton
                    id='deleteCard'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteModal();
                      set_metrics_processing_event_history_id(
                        metrics_processing_event_history_id
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('gallery.Reprocess')}>
                  <IconButton
                    id='reprocessCard'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenReprocessVideoModal();
                      setReprocessVideoData({
                        video_title: title,
                        metrics_processing_events_history_id_list:
                          metrics_processing_event_history_id,
                        sessionDetails: sessionDetails,
                        text_analysis: textAnalysisEnabled,
                        video_analysis: videoAnalysisEnabled,
                      });
                    }}
                  >
                    <SyncOutlinedIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip title={t('dashboard.Engagement')}>
              <IconButton>
                {engagement_score >= 75 ? (
                  <EngagementBadge />
                ) : (
                  <DisabledBadge />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('dashboard.Positivity')}>
              <IconButton>
                {positivity_score >= 75 ? (
                  <PositivityBadge />
                ) : (
                  <DisabledBadge />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('dashboard.Energy')}>
              <IconButton>
                {overall_energy >= 75 ? <EnergyBadge /> : <DisabledBadge />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('dashboard.Variation')}>
              <IconButton>
                {variation_score >= 75 ? <VariationBadge /> : <DisabledBadge />}
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{
              minHeight: '200px',
              height: '100%',
              display: 'flex',

              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              sx={commonTypographyStyle}
              label={truncateFilename(title.replace(/^\d{29}[_-]?/, ''), 30)}
            />

            {totalAttendees === 0 && (
              <Typography
                sx={{ ...commonTypographyStyle, color: '#d33022' }}
                label='gallery.NoSpeakersDetected'
              />
            )}

            <Typography sx={commonTypographyStyle} label=''>
              {sessionName} <br />
              {moment(new Date(`${startTime}.000Z`)).fromNow()}
            </Typography>
            <Typography
              sx={{ color: 'red', fontSize: '12px' }}
              label='There was error while processing this session'
            />
          </Box>
        </Box>
      )}
    </>
  );
}
