/** @format */

import React, { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { UploadSingleFileServiceWithProgressStatus } from 'services/UploadMedia-services';
import { Toast } from 'widgets/Toast/Toast';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import DeleteUnsavedRecordingModal from 'components/Record/DeleteUnsaveRecording';
import DialogModal from 'widgets/Dialog/DialogModal';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import RecordedVideoUploadStatusModal from './RecordedVideoUploadStatusModal';
import axios, { CancelTokenSource } from 'axios';
import { fixVideoDuration } from 'utils/fixVideoDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { featureFlagList } from 'constants/FeatureFlags';
import { getAIPermissionsSelector } from 'redux/selectors/selectors';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

const PrettoSlider = styled(Slider)({
  color: '#929294',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#1206F9',

    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
});

interface CustomVideoPlayerProps {
  mediaBlobUrl: string | null;
  videoDesc: string;
  selectedSessionType: { id: number; name: string };
  recordingType: { id: number; name: string };
  clearBlobUrl: Function;
  totalSeconds: number;
  handleClickOpenRecordedSessionUploadCompleteModal: Function;
  resetTimer: () => void;
}

function CustomVideoPlayer({
  mediaBlobUrl,
  videoDesc,
  selectedSessionType,
  recordingType,
  clearBlobUrl,
  totalSeconds,
  handleClickOpenRecordedSessionUploadCompleteModal,
  resetTimer,
}: CustomVideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [fixBlob, setFixBlob] = useState<string>('');
  const videoRef = useRef<HTMLAudioElement | null>(null);
  const dispatch = useAppDispatch();
  const { isFeatureEnable } = useFeatureFlag();
  const { t } = useTranslation();
  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);
  const togglePlay = () => {
    if (videoRef.current?.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) setCurrentTime(videoRef.current.currentTime);
  };

  const handleCanPlay = () => {
    if (videoRef.current) setDuration(videoRef.current.duration);
  };

  const handleSeekChange = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const newTime = parseFloat(e.target.value);
      if (videoRef.current) videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  useEffect(() => {
    fixVideoDuration(totalSeconds, mediaBlobUrl, setFixBlob);
    // eslint-disable-next-line
  }, [totalSeconds, mediaBlobUrl]);
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource>();
  const handleCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload canceled by user.');
      setStatus('canceled');
    }
  };

  async function uploadBlob() {
    if (selectedSessionType.id === -1) {
      Toast(t('toastMessageNotifications.PleaseAddSessionType'), 'error');
    } else {
      const videoAnalysisEnabled = false;
      // selectedSessionType.id === 59 ? getAIPermissions.video_analysis :
      const textAnalysisEnabled =
        selectedSessionType.id === 59 ? getAIPermissions.text_analysis : false;
      setStatus('uploading');
      const cancelToken = axios.CancelToken.source();
      setCancelTokenSource(cancelToken);
      handleOpenRecordedVideoUploadStatusModal();
      const Payload = {
        log: `Recorded Audio upload started`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));
      dispatch(
        UploadSingleFileServiceWithProgressStatus(
          fixBlob,
          selectedSessionType.id,
          videoDesc,
          recordingType,
          handleClickOpenRecordedSessionUploadCompleteModal,
          setUploadSingleFileProgressStatus,
          cancelToken.token,
          setStatus,
          isFeatureEnable(featureFlagList.dynamicPrediction),
          videoAnalysisEnabled,
          textAnalysisEnabled
        )
      );
      resetTimer();
    }
  }

  function handleDeleteRecordedVideo() {
    clearBlobUrl();
  }

  const [openDeleteUnsavedRecordingModal, setOpenDeleteUnsavedRecordingModal] =
    React.useState(false);

  const handleClickOpenDeleteUnsavedRecordingModal = () => {
    setOpenDeleteUnsavedRecordingModal(true);
  };

  const handleCloseDeleteUnsavedRecordingModal = () => {
    setOpenDeleteUnsavedRecordingModal(false);
  };

  const [openSaveSessionModal, setOpenSaveSessionModal] = useState(false);
  function handleCloseSaveSessionModal() {
    setOpenSaveSessionModal(false);
    handleOpenDeleteConfirmationModal();
    resetTimer();
  }
  function handleOpenSaveSessionModal() {
    setOpenSaveSessionModal(true);
  }

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);

  function handleOpenDeleteConfirmationModal() {
    setOpenDeleteConfirmationModal(true);
  }
  function handleCloseDeleteConfirmationModal() {
    setOpenDeleteConfirmationModal(false);
    handleDeleteRecordedVideo();
  }
  useEffect(() => {
    handleOpenSaveSessionModal();
  }, []);

  const [
    openRecordedVideoUploadStatusModal,
    setOpenRecordedVideoUploadStatusModal,
  ] = useState(false);
  const [UploadSingleFileProgressStatus, setUploadSingleFileProgressStatus] =
    useState({ fileName: '', uploadProg: 0 });

  function handleOpenRecordedVideoUploadStatusModal() {
    setOpenRecordedVideoUploadStatusModal(true);
  }

  function handleClosedRecordedVideoUploadStatusModal() {
    setOpenRecordedVideoUploadStatusModal(false);
  }
  const handleRetryUpload = () => {
    setStatus('retrying');
    uploadBlob();
  };

  const [uploadFileStatus, setStatus] = useState('idle');
  return (
    <div
      style={{
        width: '100%',
        height: '98%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '10px',
      }}
    >
      <DeleteUnsavedRecordingModal
        openDeleteUnsavedRecordingModal={openDeleteUnsavedRecordingModal}
        handleCloseDeleteUnsavedRecordingModal={
          handleCloseDeleteUnsavedRecordingModal
        }
        videoDesc={videoDesc}
        handleDeleteRecordedVideo={handleDeleteRecordedVideo}
      />
      <DialogModal
        onClickCancelButton={handleCloseSaveSessionModal}
        confirmButtonLabel={t('record.YesSaveIt')}
        cancelButtonLabel={t('record.NoRemoveIt')}
        dialogModalTitle={t('record.UploadRecordedSession')}
        dialogModalBody={t('record.YourSessionHasBeenRecordedDoYouWantToSave')}
        openDialogModal={openSaveSessionModal}
        onClickConfirmButton={uploadBlob}
      />
      <ConfirmationModal
        message={t('record.YourSessionHasBeenDeleted')}
        openConfirmationModal={openDeleteConfirmationModal}
        handleCloseConfirmationModal={handleCloseDeleteConfirmationModal}
      />{' '}
      <RecordedVideoUploadStatusModal
        openRecordedVideoUploadStatusModal={openRecordedVideoUploadStatusModal}
        handleClosedRecordedVideoUploadStatusModal={
          handleClosedRecordedVideoUploadStatusModal
        }
        UploadSingleFileProgressStatus={UploadSingleFileProgressStatus}
        handleCancelUpload={handleCancelUpload}
        handleRetryUpload={handleRetryUpload}
        uploadFileStatus={uploadFileStatus}
      />
    </div>
  );
}

export default CustomVideoPlayer;
