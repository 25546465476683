import React, { useCallback, useEffect, useRef, useState } from 'react';
import react from 'react';
import { MicrophoneDetails, CameraDetails } from 'types/MyAssessments-types';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import RecordRTC from 'recordrtc';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import QuestionnaireAudioIcon from 'icons/QuestionnaireAudioIcon';
import QuestionnaireVideoIcon from 'icons/QuestionnaireVideoIcon';
import QuestionnaireVideoOffIcon from 'icons/QuestionnaireVideoOffIcon';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import QuestionnaireTextIcon from 'icons/QuestionnaireTextIcon';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};

function PatientVideoIosRecorder({
  onRecordingComplete,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  Questionnaire,
  selectedVideoDevice,
  selectedAudioDevice,
  status,
  mediaBlobUrl,
  isReUpload,
  isUploaded,
  isCameraEnabled,
  setIsCameraEnabled,
}: {
  onRecordingComplete: (
    duration: number,
    isPrev: boolean,
    blobUrl?: Blob | MediaSource | string | null,
    TextAnswer?: string
  ) => void;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  Questionnaire: Array<QuestionnaireData>;
  selectedVideoDevice: MicrophoneDetails;
  selectedAudioDevice: CameraDetails;
  status: string;
  mediaBlobUrl: string | Blob | MediaSource;
  isReUpload: boolean;
  isUploaded: boolean;
  isCameraEnabled: number;
  setIsCameraEnabled: React.Dispatch<React.SetStateAction<number>>;
}) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');

  const [recording, setRecording] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const videoRef = useRef<any>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  let blob: Blob | MediaSource;
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const totalSecondsRef = useRef<number>(0);
  const [TextAnswer, setResponseText] = useState<string | null>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording) {
      handleStartRecording();
      setRecording(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line
  }, [recording]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds((prev) => {
          const newTotal = prev + 1;
          totalSecondsRef.current = newTotal;
          return newTotal;
        });
      }, 1000);

      if (totalSeconds === 600) {
        handleStopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (isRecording) {
      startTimer();
    }

    if (!isRecording) {
      pauseTimer();
    }
  }, [isRecording]);

  const startTimer = useCallback(() => {
    if (!isRecording) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRecording) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = () => {
    setIsRunning(false);
    setTotalSeconds(0);
    totalSecondsRef.current = 0;
    setMinutes(0);
    setSeconds(0);
  };

  const handlePreviousQuestion = async () => {
    const recordedSeconds = totalSecondsRef.current;
    if (isCameraEnabled !== 2) {
      if (videoURL) {
        onRecordingComplete(recordedSeconds, true, videoURL);
        setVideoURL(null);
        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

          return newIndex;
        });
      }
    } else {
      if (TextAnswer && TextAnswer.trim() !== '' && textFieldRef.current) {
        onRecordingComplete(recordedSeconds, false, undefined, TextAnswer);
        textFieldRef.current.value = '';
        setResponseText(null);

        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

          return newIndex;
        });
      }
    }
  };

  const handleStartRecording = () => {
    if (recorder) {
      recorder.startRecording();
      setIsRecording(true);
    }
  };

  const handleStopRecording = useCallback(() => {
    if (recorder) {
      recorder.stopRecording(() => {
        blob = recorder.getBlob();
        setRecordedChunks((prevChunks) => [...prevChunks, blob]);
        const url = URL.createObjectURL(blob);
        setVideoURL(url);
        setIsRecording(false);
      });
    }
  }, [recorder, videoURL]);

  const handleNext = () => {
    setIsRecording(false);
    const recordedSeconds = totalSecondsRef.current;
    if (isCameraEnabled !== 2) {
      if (videoURL) {
        onRecordingComplete(recordedSeconds, false, videoURL);
        setVideoURL(null);
        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          if (prevIndex < Questionnaire.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }
    } else {
      if (TextAnswer && TextAnswer.trim() !== '' && textFieldRef.current) {
        onRecordingComplete(recordedSeconds, false, undefined, TextAnswer);
        textFieldRef.current.value = '';
        setResponseText(null);

        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

          return newIndex;
        });
      }
    }
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number | null
  ) => {
    if (newAlignment !== null) {
      setIsCameraEnabled(newAlignment);
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    const getMediaStream = async () => {
      try {
        let stream: MediaStream;

        if (isCameraEnabled === 0) {
          stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { exact: 640 },
              height: { exact: 360 },
            },
            audio: true,
          });

          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.muted = true;
            videoElement.play();
          }

          const recorderInstance = new RecordRTC(stream, {
            type: 'video',
            mimeType: 'video/webm',
            canvas: { width: 640, height: 360 },
          });

          setRecorder(recorderInstance);
        } else if (isCameraEnabled === 1) {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          if (videoElement?.srcObject) {
            const existingStream = videoElement.srcObject as MediaStream;
            const videoTracks = existingStream.getVideoTracks();
            videoTracks.forEach((track) => track.stop());
            videoElement.srcObject = null;
          }

          const recorderInstance = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm',
          });

          setRecorder(recorderInstance);
        }
      } catch (error) {}
    };

    getMediaStream();

    return () => {
      if (videoElement?.srcObject) {
        const stream = videoElement.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoElement.srcObject = null;
      }
    };
  }, [isCameraEnabled]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponseText(JSON.stringify(event.target.value));
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          mb: isCameraEnabled === 2 ? 2 : 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '8px',
            padding: '10px',
            zIndex: 10,
            gap: '10px',
            position: isCameraEnabled === 2 ? 'relative' : 'absolute',
            top: isCameraEnabled === 2 ? '0' : '10px',
            left: isCameraEnabled === 2 ? '0' : '10px',
            width: isCameraEnabled === 2 ? 'fit-content' : 'auto',
          }}
        >
          <Typography
            label=' Select your response mode'
            sx={{ fontSize: '10px', color: '#E0E0E0' }}
          />

          <ToggleButtonGroup
            value={isCameraEnabled}
            exclusive
            onChange={handleAlignment}
            disabled={isRecording}
          >
            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 0 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 0 ? '#1206f9' : '#fff',
                },
              }}
              value={0}
              aria-label='Video'
            >
              <QuestionnaireVideoIcon isSelected={isCameraEnabled === 0} />
            </ToggleButton>

            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 1 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 1 ? '#1206f9' : '#fff',
                },
              }}
              value={1}
              aria-label='Audio'
            >
              <QuestionnaireAudioIcon isSelected={isCameraEnabled === 1} />
            </ToggleButton>

            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 2 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 2 ? '#1206f9' : '#fff',
                },
              }}
              value={2}
              aria-label='Text'
            >
              <QuestionnaireTextIcon isSelected={isCameraEnabled === 2} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ position: 'relative' }}>
          {isCameraEnabled === 0 ? (
            <video
              ref={videoRef}
              id='MemoizedVideoPreview'
              autoPlay
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                aspectRatio: 16 / 9,
                objectFit: 'cover',
                transform: 'scaleX(-1)',
              }}
            />
          ) : isCameraEnabled === 1 ? (
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: 'black',
                minHeight: '55vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <QuestionnaireVideoOffIcon />
            </Box>
          ) : isCameraEnabled === 2 ? (
            <Box sx={{ width: '100%', maxHeight: '40vh', height: 'auto' }}>
              <TextField
                id='Chat_Text_field'
                label='Write Your Response'
                multiline
                rows={11}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                  '& .MuiInputLabel-root.MuiFormLabel-filled': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                }}
                fullWidth
                placeholder=''
                variant='filled'
                onChange={handleTextChange}
                inputRef={textFieldRef}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          width: '95%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          p: 1,
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Button
            ref={nextButtonRef}
            sx={{ color: '#1206F9' }}
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 1 || status === 'recording'}
          >
            Prev
          </Button>
          {isCameraEnabled !== 2 && (
            <>
              {isRecording ? (
                <Button
                  sx={{
                    backgroundColor: '#1206F9',
                    borderRadius: '30px',
                    border: '1px solid #1206F9',
                  }}
                  variant='contained'
                  startIcon={<StopRecordingIcon />}
                  onClick={handleStopRecording}
                  id='stopRecordingButton'
                >
                  Stop
                </Button>
              ) : (
                <Box>
                  <Button
                    onClick={handleStartRecording}
                    sx={{
                      backgroundColor:
                        isReUpload || videoURL ? '#FFF' : '#1206F9',
                      color: isReUpload || videoURL ? '#1206f9' : '#FFF',
                      borderRadius: '30px',
                      textTransform: 'none',
                    }}
                    size='small'
                    variant={isReUpload || videoURL ? 'text' : 'contained'}
                    startIcon={<StartAssessmentRecordIcon />}
                    id='startRecordingButton'
                  >
                    {isReUpload || videoURL
                      ? 'Re-record My Response'
                      : 'Record My Response'}
                  </Button>
                </Box>
              )}
            </>
          )}
          <Button
            sx={{
              color: isReUpload || videoURL || TextAnswer ? '#FFF' : '#1206f9',
              backgroundColor:
                isReUpload || videoURL || TextAnswer
                  ? '#1206f9'
                  : 'transparent',
              borderRadius: '20px',
              '&:hover': {
                color:
                  isReUpload || videoURL || TextAnswer ? '#FFF' : '#1206f9',
                backgroundColor:
                  isReUpload || videoURL || TextAnswer
                    ? '#1206f9'
                    : 'transparent',
              },
            }}
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNext}
            variant={
              isReUpload || videoURL || TextAnswer ? 'contained' : 'text'
            }
            disabled={
              isRecording || isCameraEnabled === 2
                ? !isUploaded && !TextAnswer
                : !isUploaded && !videoURL
            }
            id={
              currentQuestionIndex === Questionnaire.length - 1
                ? 'FinishButton'
                : 'NextButton'
            }
          >
            {currentQuestionIndex === Questionnaire.length - 1
              ? 'Finish'
              : 'Next'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

export default PatientVideoIosRecorder;
