import axios, { AxiosHeaderValue } from 'axios';
import { i18n } from 'i18n/i18n';
import {
  getRecoveryDashboardDataFailure,
  getRecoveryDashboardDataLoad,
  getRecoveryDashboardDataSuccess,
} from 'redux/slices/PatientWorkFlow/RecoveryDashboard-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import { decryptApiResponse } from 'shared/AesDecryption/AesDecryption';

export const getRecoveryDashboardDataService =
  (apiKey: AxiosHeaderValue): AppThunk =>
    async (dispatch) => {
      if (apiKey) {
        dispatch(getRecoveryDashboardDataLoad());

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL +
            ProtectedUrls.getRecoveryDashboardData.url()
            }`,
            {
              headers: { 'x-api-key': apiKey },
            }
          );
          if (response) {
            // const APIResponse = decryptApiResponse(response.data);
            const APIResponse = response.data;

            dispatch(getRecoveryDashboardDataSuccess(APIResponse));
          }
        } catch (error) {
          dispatch(
            getRecoveryDashboardDataFailure(
              i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
            )
          );
        }
      }
    };
