import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Box,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PauseIconForPatientQuestionaire from 'icons/PauseIconForPatientQuestionaire';
import PlayIconForPatientQuestionaire from 'icons/PlayIconForPatientQuestionaire';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import { IosVideoPlayer } from './IosVideoPlayer';
interface VideoPlayerProps {
  url: string;
  questionData: {
    question: string;
    questionNum: number;
    followUpQuestions: string;
  };
  onCanPlay?: () => void;
  isVideoLoading: boolean;
  status: string;
  isIOS: boolean;
  setIsPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayed: boolean;
}

const QuestionVideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  questionData,
  onCanPlay,
  isVideoLoading,
  status,
  isIOS,
  setIsPlayer,
  isPlayed,
}) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallDevice = useMediaQuery('(max-width: 245px)');
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const [isPlayerPlaying, setIsPlayerPlaying] = useState<boolean>(false);
  const [localIsPlayed, setLocalIsPlayed] = useState<boolean>(isPlayed);

  useEffect(() => {
    if (status === 'recording' && videoRef.current) {
      videoRef.current.pause();
    }
  }, [status]);

  const handlePlay = () => {
    if (playerRef.current) {
      if (isPlayerPlaying) {
        playerRef.current.pause();
        setIsPlayer(true);
      } else {
        playerRef.current.play();
      }
      setIsPlayerPlaying(!isPlayerPlaying);
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        setIsPlayer(false);
        videoRef.current.pause();
      } else {
        setIsPlayer(true);
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleRepeat = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;

      if (isPlaying) {
        videoRef.current.play();
        setIsPlayer(true);
      } else {
        handlePlayPause();
      }
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      setIsPlaying(false);
      setLocalIsPlayed(true);
      setIsPlayer(true);
    }
  };
  const TogglePlayPause = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (event.currentTarget.paused) {
      setLocalIsPlayed(false);
      setIsPlayer(false);
    } else {
      setLocalIsPlayed(true);
      setIsPlayer(true);
    }
  };

  if (isVideoLoading && videoRef.current) {
    videoRef.current.pause();
  }
  return (
    <Box>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper>
          <Box sx={{ position: 'relative' }}>
            {isIOS ? (
              // <video
              //   id='livePreview'
              //   ref={playerRef}
              //   playsInline
              //   autoPlay

              //   poster={url}
              //   width='100%'
              //   height='100%'
              //   style={{
              //     width: '100%',
              //     aspectRatio: 16 / 9,
              //     objectFit: 'cover',
              //   }}
              // >
              //   <source src={url} type='video/mp4' />
              // </video>
              <IosVideoPlayer
                url={url}
                poster={url}
                isPlayed={isPlayed}
                setIsPlayer={setIsPlayer}
              />
            ) : (
              <video
                id='livePreview'
                playsInline
                autoPlay={localIsPlayed}
                controls
                ref={videoRef}
                style={{
                  width: '100%',
                  aspectRatio: 16 / 9,
                  objectFit: 'cover',
                }}
                onCanPlay={onCanPlay}
                onEnded={handleVideoEnd}
                onPlay={TogglePlayPause}
                onPause={TogglePlayPause}
              >
                <source src={url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}

            {/* <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              {isPlaying ? (
                <IconButton onClick={handlePlayPause}>
                  <PauseIconForPatientQuestionaire />
                </IconButton>
              ) : (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <IconButton
                    onClick={isIOS ? handlePlay : handlePlayPause}
                    id='PlayPauseButton'
                    sx={{
                      backgroundColor: 'rgba(255,255,255, 0.2)',
                      borderRadius: '50%',
                      p: isSmallDevice ? 0 : isMobile ? 1.5 : 4,
                    }}
                  >
                    <StartAssessmentRecordIcon />
                  </IconButton>{' '}
                  {isMobileOrTablet && (
                    <IconButton
                      onClick={handleRepeat}
                      sx={{
                        backgroundColor: 'rgba(255,255,255, 0.2)',
                        borderRadius: '50%',
                        p: isSmallDevice ? 0 : isMobile ? 1 : 3.5,
                      }}
                    >
                      <CachedOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box> */}
          </Box>{' '}
          <Box
            sx={{
              pt: 1.3,
              pl: 2,
              pr: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection:
                isMobileOrTablet || isSmallDevice ? 'column-reverse' : 'row',
              gap: 2,
            }}
          >
            <Typography
              label={`${questionData.questionNum}. ${questionData.question}`}
            />
            {!isMobileOrTablet && (
              <Button
                onClick={handleRepeat}
                sx={{
                  color: '#1306F9',
                  textTransform: 'none',
                  height: '100%',
                }}
                id='repeatButton'
                startIcon={<CachedOutlinedIcon sx={{ color: '#1306F9' }} />}
              >
                Repeat
              </Button>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'fit-content',
              minHeight: '30px',
              p: 1,

              textOverflow: 'ellipsis',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <Typography
              label=''
              sx={{
                fontSize: '16px',
                color: '#817C7C',
                pr: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              <pre
                id='followUpQuestions'
                style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              >
                {questionData.followUpQuestions}
              </pre>
            </Typography>
          </Box>
        </Paper>
      </div>
    </Box>
  );
};

export default QuestionVideoPlayer;
