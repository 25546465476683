import { createSlice } from '@reduxjs/toolkit';
import { addAuditlogsInitialStateTypes } from 'types/common/Common-types';

const addAuditLogsInitialState: addAuditlogsInitialStateTypes = {
  isLoadingAddLogs: false,
  errorAddLogs: '',
  addLogs: '',
};

const addAuditLogsSlice = createSlice({
  name: 'api',
  initialState: addAuditLogsInitialState,
  reducers: {
    addAuditLogsLoad(state) {
      state.isLoadingAddLogs = true;
    },
    addAuditLogsSuccess(state, action) {
      state.isLoadingAddLogs = false;
      state.addLogs = action.payload;
    },
    addAuditLogsFailure(state, action) {
      state.isLoadingAddLogs = false;
      state.errorAddLogs = action.payload;
    },
  },
});

export const { addAuditLogsLoad, addAuditLogsSuccess, addAuditLogsFailure } =
  addAuditLogsSlice.actions;
export { addAuditLogsSlice };
