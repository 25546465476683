import React, { useState, useEffect } from 'react';
import { useMediaQuery, Box, Paper } from '@mui/material';
import Footer from 'shared/Footer/Footer';
import {
  getUserDetailsAsUserServiceApi,
  sendForgetPasswordMailServiceApi,
} from 'services/UserDetails-service';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUserDetailsAsUserSelector } from 'redux/selectors/selectors';
import { useHistory } from 'react-router-dom';

import Loader from 'widgets/Loader/Loader';
import { useTranslation } from 'react-i18next';

import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import { OpenRoutes } from 'routes/routes';
import { Toast } from 'widgets/Toast/Toast';
import Typography from 'widgets/Typography/Typography';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function ForgotPasswordForm() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { t } = useTranslation();
  const { UserDetailsData } = useAppSelector(getUserDetailsAsUserSelector);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError(t('ForgotPassword.emailidisrequired'));
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError(t('ForgotPassword.Invalidemailformat'));
      return;
    }

    const payload = {
      email_address: email,
    };

    try {
      await dispatch(getUserDetailsAsUserServiceApi(payload));
      setEmailSubmitted(true);
    } catch (error) {}
  };

  useEffect(() => {
    const sendResetEmail = async () => {
      setIsLoading(false);
      if (emailSubmitted) {
        const userDetails = UserDetailsData?.[0];
        if (!userDetails) {
          setIsLoading(false);
          Toast(t('toastMessageNotifications.UserDoesNotExist'), 'error');

          return;
        }

        if (userDetails.auth_provider !== 'Mirro') {
          Toast(
            t(
              'toastMessageNotifications.ThisAccountIsConfiguredForSingleSignOnSSOLoginPleaseUseTheSSOLoginOptionToAccessyouraccount'
            ),
            'error'
          );
          history.push(OpenRoutes.signInRoute);
          return;
        }

        const userHash = userDetails.user_hash;
        const encodedUserHash = btoa(userHash);
        const encodedEmail = btoa(email);
        const separator = '|';
        const combinedData = encodedEmail + separator + encodedUserHash;
        const resetPasswordURL = `${window.location.origin}/#/ResetPassword/?token=${combinedData}`;
        const payload = {
          email: email,
          resetLink: resetPasswordURL,
          logofootpath: `${window.location.origin}/LoginImg/mirro-ai-logo-22.png`,
          homepagelink: window.location.origin,
          logopath: `${window.location.origin}/LoginImg/image-205.png`,
        };

        try {
          const Payload = {
            log: `Forgot Password mail sent`,
            log_type: 'info',
          };
          dispatch(AddAuditLogService(Payload));
          await dispatch(sendForgetPasswordMailServiceApi(payload));
          history.push(OpenRoutes.signInRoute);
        } catch (error) {
          Toast(
            t('toastMessageNotifications.Errorsendingresetlinkmail'),
            'error'
          );
          return;
        } finally {
          setIsLoading(false);
        }
      }
    };

    sendResetEmail(); // Call the async function
  }, [emailSubmitted, UserDetailsData, dispatch]);

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : isTablet ? '70%' : '50%',
        height: '65vh',
        zIndex: 1,
        ml: -1,
        mt: -2,
      }}
    >
      {isLoading ? <Loader /> : null}
      <Paper
        elevation={2}
        sx={{
          height: '100%',
          borderRadius: '6px',
          padding: 5,
          border: '2px solid #D4D4D4',
        }}
      >
        <Typography
          sx={{
            color: '#33344B',
            fontFamily: 'Poppins',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '23px',
          }}
          label='Forgot Password'
        />
        <Box
          sx={{
            marginTop: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'block' }}>
              <TextField
                label={'forgotresetpassword.RegisteredEmailAddress'}
                fullWidth
                variant='outlined'
                value={email}
                onChange={handleEmailChange}
                error={!!emailError}
                helperText={emailError}
                sx={{ width: '100%' }}
              />
              <Button
                sx={{
                  backgroundColor: '#1206f9',
                  padding: 2,
                  textAlign: 'center',
                  mt: 3,
                  borderRadius: '25px',
                  color: '#fff',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: '#1206f9',
                  },
                }}
                id='btnForgotPassword'
                variant='contained'
                onClick={handleSubmit}
                label='forgotresetpassword.GetLink'
              />
            </Box>
          </form>

          <Typography
            onClick={() => history.push(OpenRoutes.signInRoute)}
            id='RedirectToSignIN'
            sx={{
              color: '#1206F9',
              fontWeight: ' 600',
              lineHeight: '22px',
              mt: 1,
              cursor: 'pointer',
            }}
            label='Back to login'
          />
        </Box>
      </Paper>
      <Footer />
    </Box>
  );
}

export default ForgotPasswordForm;
