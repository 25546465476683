/** @format */
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
} from '@mui/material';
import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';
import DragDropIcon from 'icons/DragDropIcon';
import React, { useEffect, useRef, useState } from 'react';
import {
  getAIPermissionsSelector,
  uploadMultipleFilesSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import { Toast } from 'widgets/Toast/Toast';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import UploadStatusTableMobileView from './UploadStatusTableMobileView';
import UploadStatusTableDesktopView from './UploadStatusTableDesktopView';
import { useTranslation } from 'react-i18next';
import { removeFilesFromArray } from 'utils/removeFilesFromArray';
import { useFiles } from 'hooks/usefiles';
import Typography from 'widgets/Typography/Typography';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { featureFlagList } from 'constants/FeatureFlags';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function UploadWithDragDrop({
  selectedSessionType,
  outputPeriodMsec,
}: {
  selectedSessionType: {
    id: number;
    name: string;
  };
  outputPeriodMsec: number;
}) {
  const { t } = useTranslation();
  const maxFiles = 10;
  const $input = useRef<HTMLInputElement | null>(null);
  const [isHoverDropzone, setIsHoverDropzone] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [fileListProgress, setFileListProgress] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(0);
  const [totalCancelUploads, setTotalCancelUploads] = useState(0);
  const [over, setover] = useState(false);
  const [files, setfiles] = useFiles({ maxFiles });
  const [cancelTokenSources, setCancelTokenSources] = useState<
    CancelTokenSource[]
  >([]);
  const { uploadMultipleFilesError } = useAppSelector(
    uploadMultipleFilesSelector
  );

  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const { isFeatureEnable } = useFeatureFlag();
  const dispatch = useAppDispatch();
  const uploadFile = async (
    file: File,
    index: number,
    cancelTokenSource: CancelTokenSource
  ) => {
    const formData = new FormData();
    formData.append('files', file);

    const videoAnalysisEnabled = getAIPermissions.video_analysis;
    const textAnalysisEnabled = getAIPermissions.text_analysis;

    const Payload = {
      log: `video upload started`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));

    try {
      await commonAPI.post(
        ProtectedUrls.uploadSingleFile.url(
          selectedSessionType.id,
          isFeatureEnable(featureFlagList.dynamicPrediction),
          videoAnalysisEnabled,
          textAnalysisEnabled,
          outputPeriodMsec
        ),
        formData,
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const percentCompleted = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;

            const updatedFiles = [...files];
            updatedFiles[index].progress = percentCompleted;
            setfiles(updatedFiles);
          },
          cancelToken: cancelTokenSource.token,
        }
      );
      setUploadSuccess((prev: number) => prev + 1);
    } catch (error) {
      if (axios.isCancel(error)) {
        setTotalCancelUploads((prev) => prev + 1);
      } else {
      }
    }
  };
  const handleUpload = async () => {
    const promises: Promise<void>[] = [];

    files.forEach((file: File, index: number) => {
      promises.push(uploadFile(file, index, cancelTokenSources[index]));
    });

    try {
      await Promise.all(promises);
    } catch (error) {}
  };
  const handleCancel = (index: number) => {
    if (cancelTokenSources[index]) {
      cancelTokenSources[index].cancel(
        `Upload for file ${files[index].name} canceled by user`
      );
    }
    const updatedFiles = [...files];
    updatedFiles[index].isCanceled = true;
    setfiles(updatedFiles);
  };

  function handleCLoseUploadProgress() {
    setfiles([]);
    setCancelTokenSources([]);
    setApiCalled(false);
    setUploadSuccess(0);
    setTotalCancelUploads(0);
    setFileListProgress(false);
  }
  const handleRetry = async (index: number) => {
    const updatedFiles = [...files];
    updatedFiles[index].isCanceled = false;
    setfiles(updatedFiles);
    const newCancelTokenSource = axios.CancelToken.source();
    const updatedCancelTokenSources = [...cancelTokenSources];
    updatedCancelTokenSources[index] = newCancelTokenSource;
    setCancelTokenSources(updatedCancelTokenSources);

    setTotalCancelUploads((prev) => prev - 1);
    await uploadFile(files[index], index, newCancelTokenSource);
  };

  useEffect(() => {
    if (files.length > 0 && !apiCalled) {
      setApiCalled(true);
      setFileListProgress(true);
      handleUpload();
    }
  }, [files]);
  return (
    <Box>
      {fileListProgress && (
        <Box
          sx={{
            width: '80%',
            height: '80%',
            margin: '2rem auto',
            border: '1px solid #d4dfff',
            marginBottom: '5%',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            cursor: 'pointer',
            minHeight: '50vh',
            borderRadius: '25px',
            position: 'relative',
          }}
        >
          {files.length === totalCancelUploads ? (
            <Typography
              sx={{
                color: 'red',
                m: 3,
              }}
              label='upload.AllFilesWereCancelled'
            />
          ) : (
            files.length === uploadSuccess + totalCancelUploads && (
              <Typography
                sx={{
                  color: '#00D8A5',
                  margin: 3,
                  pt: isMobile || isTablet ? 5 : 0,
                }}
                label='upload.FileUploadedSuccessfullyNowYouCanCheckTheAnalysisForYourFileInTheDashboard'
              >
                <Typography
                  sx={{ color: '#00D8A5' }}
                  label='upload.ToViewYourMediaFileGoToGallery'
                />
              </Typography>
            )
          )}
          <Button
            sx={{ position: 'absolute', right: 0, top: 15 }}
            onClick={handleCLoseUploadProgress}
          >
            <CloseIcon />
          </Button>
          {isMobile ? (
            <UploadStatusTableMobileView
              files={files}
              handleCancel={handleCancel}
              uploadMultipleFilesError={uploadMultipleFilesError}
              handleRetry={handleRetry}
              uploadSuccess={uploadSuccess}
              totalCancelUploads={totalCancelUploads}
            />
          ) : (
            <UploadStatusTableDesktopView
              files={files}
              handleCancel={handleCancel}
              uploadMultipleFilesError={uploadMultipleFilesError}
              handleRetry={handleRetry}
            />
          )}
        </Box>
      )}

      {!fileListProgress && (
        <div
          onClick={() => {
            if ($input.current) {
              $input.current.click();
            }
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.persist();
            const updatedFiles = [...files, ...e.dataTransfer.files];

            setfiles(updatedFiles);

            const newFiles = Array.from(e.dataTransfer.files || []);

            const newCancelTokenSources = newFiles.map(() =>
              axios.CancelToken.source()
            );
            setCancelTokenSources((prevSources) => [
              ...prevSources,
              ...newCancelTokenSources,
            ]);
            setover(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setover(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setover(false);
          }}
          onMouseEnter={() => setIsHoverDropzone(true)}
          onMouseLeave={() => setIsHoverDropzone(false)}
          className={
            over
              ? 'upload-container over uploader'
              : 'upload-container uploader'
          }
          style={{
            border: '1px solid #d4dfff',
            marginBottom: '5%',
            backgroundColor: isHoverDropzone ? '' : '#f9f9f9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            cursor: 'pointer',
          }}
        >
          <div>
            <DragDropIcon />
          </div>
          <Typography
            sx={{ fontFamily: 'poppins' }}
            label='upload.DragAndDropVideoAudioToUpload'
          />

          <Typography
            sx={{ fontFamily: 'poppins' }}
            label='upload.AviMp4Mp3WavMkvM4aInLessThan5GB'
          />

          <Button
            variant='contained'
            sx={{
              borderRadius: '25px',
              backgroundColor: '#1206F9',
              fontFamily: 'poppins',
            }}
          >
            {t('upload.OrChooseFiles')}
          </Button>

          <input
            style={{ display: 'none' }}
            type='file'
            ref={$input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newFiles = Array.from(e.target.files || []);
              setfiles([...files, ...newFiles]);

              const newCancelTokenSources = newFiles.map(() =>
                axios.CancelToken.source()
              );
              setCancelTokenSources((prevSources) => [
                ...prevSources,
                ...newCancelTokenSources,
              ]);
            }}
            multiple={maxFiles > 1}
            accept='audio/*, video/*'
          />
        </div>
      )}
    </Box>
  );
}

export default UploadWithDragDrop;
