import React, { useState, useEffect } from 'react';
import { useMediaQuery, Box, Paper } from '@mui/material';
import Footer from 'shared/Footer/Footer';
import { getUserDetailsAsUserServiceApi } from 'services/UserDetails-service';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUserDetailsAsUserSelector } from 'redux/selectors/selectors';
import MirroBranding from 'components/SignIn/MirroBranding';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { forgetPasswordService } from 'services/ForgetPassword-Service';
import { Toast } from 'widgets/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { OpenRoutes } from 'routes/routes';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function ForgotPasswordLink() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [Hash, setHash] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [decodedEmail, setDecodedEmail] = useState('');
  const [decodedUserHash, setDecodedUserHash] = useState('');
  const { combinedData } = useParams<{ combinedData: string }>();
  const [apiKey, setApiKey] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const successParam = searchParams.get('token');

        if (successParam) {
          const [encodedEmail, encodedUserHash] = successParam.split('|');
          const receivedEmail = atob(encodedEmail);
          const receivedUserHash = atob(encodedUserHash);

          setDecodedEmail(receivedEmail);
          setDecodedUserHash(receivedUserHash);

          const payload = {
            email_address: receivedEmail,
          };

          // Use await to wait for the dispatch to complete
          await dispatch(getUserDetailsAsUserServiceApi(payload));
        } else {
          Toast(t('toastMessageNotifications.InvalidUrl'), 'error');
        }
      } catch (error) {
        Toast(t('toastMessageNotifications.InvalidUrl'), 'error');
      }
    };

    fetchData(); // Call the async function
  }, [dispatch, location.search]);

  // The rest of your component code...

  const { UserDetailsData } = useAppSelector(getUserDetailsAsUserSelector);

  useEffect(() => {
    if (
      UserDetailsData &&
      UserDetailsData[0]?.user_hash &&
      UserDetailsData[0]?.apikey
    ) {
      setHash(UserDetailsData[0].user_hash);
      setApiKey(UserDetailsData[0].apikey);
      sessionStorage.setItem('apiKey', apiKey);
    }
  }, [UserDetailsData, apiKey]);

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);

    if (event.target.value !== confirmPassword) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);

    if (newPassword !== event.target.value) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const handlePasswordUpdate = async () => {
      if (decodedUserHash === Hash) {
        if (!passwordsMatch) {
          Toast(t('toastMessageNotifications.PasswordDidNotmatch'), 'error');
          return;
        }

        const payload = {
          new_password: newPassword,
          userHash: Hash,
        };

        try {
          const Payload = {
            log: `Password Changed`,
            log_type: 'info',
          };
          dispatch(AddAuditLogService(Payload));

          await dispatch(forgetPasswordService(Hash, newPassword));

          history.push(OpenRoutes.signInRoute);
        } catch (error) {
          Toast(t('toastMessageNotifications.ErrorUpdatingPassword'), 'error');
        }
      } else {
        Toast(t('toastMessageNotifications.InvalidUrl'), 'error');
      }
    };

    handlePasswordUpdate();
  };

  return (
    <Box
      sx={{
        width: '99vw',
        height: '98vh',
        background: '#FBFBFF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: isMobile || isTablet ? 2 : 15,
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          height: '30%',
          gap: isMobile || isTablet ? 4 : 0,
          alignItems: isMobile || isTablet ? 'center' : 'left',
        }}
      >
        <MirroBranding />
        <Paper
          elevation={2}
          sx={{
            height: '100%',
            minHeight: '65vh',
            borderRadius: '6px',
            padding: 5,
            border: '2px solid #D4D4D4',
            zIndex: '2',
          }}
        >
          <Box sx={{ gap: '25%', display: 'block' }}>
            <Typography
              sx={{
                color: '#33344B',
                fontFamily: 'Poppins',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '23px',
              }}
              label='forgotresetpassword.ResetPassword'
            />

            <form onSubmit={handleSubmit}>
              <TextField
                label={'forgotresetpassword.NewPassword'}
                fullWidth
                variant='outlined'
                type='password'
                value={newPassword}
                onChange={handleNewPasswordChange}
                sx={{ marginTop: '10%' }}
              />
              <TextField
                label={'forgotresetpassword.ConfirmPassword'}
                fullWidth
                variant='outlined'
                type='password'
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!passwordsMatch} // Add error styling if passwords don't match
                helperText={
                  !passwordsMatch &&
                  t('forgotresetpassword.PasswordsDonotMatch')
                } // Display error message
                sx={{ marginTop: '10%' }}
              />
              <Button
                sx={{
                  backgroundColor: '#1206f9',
                  borderRadius: '25px',
                  mt: 5,
                  width: '100%',
                }}
                id='btnUpdatePassword'
                type='submit'
                variant='contained'
                // onClick={handleSubmit}
                label='forgotresetpassword.UpdatePassword'
              />
            </form>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: isMobile || isTablet ? 80 : 0,
        }}
      >
        <Box sx={{ width: '80%' }}>
          <img
            src='./LoginImg/mirro-ribbon-1.png'
            alt='mirro-ribbon'
            width={'100%'}
            style={{
              maxWidth: '100%',
              height: '100%',
            }}
          />
        </Box>

        <Box>
          <img
            src='./LoginImg/mirro-ribbon-2.png'
            alt='mirro-ribbon'
            width={'100%'}
            style={{
              maxWidth: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPasswordLink;
