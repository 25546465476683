/** @format */

import React, { useEffect, useState } from 'react';
import { Toolbar, Typography, Button } from '@mui/material';
import ColumnsListTableIcon from 'icons/ColumnsListTableIcon';
import { useTranslation } from 'react-i18next';
import DeleteModal from 'widgets/DeleteModal/DeleteModal';
import NestedModal from '../Gallery/ReprocessNestedModal';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getAIPermissionsSelector,
  getProcessDataListVersion2Selector,
  getReprocessVersion2VideoSelector,
  getSessionTypesSelector,
} from 'redux/selectors/selectors';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import {
  GetReprocessVideoServiceProps,
  ProcessDataListVersion2,
} from 'types/Gallery.types';
import ReprocessBulkIcon from 'icons/ReprocessBulkIcon';
import DeleteBulkInactiveIcon from 'icons/DeleteBulkInactiveIcon';
import DeleteBulkActiveIcon from 'icons/DeleteBulkActiveIcon';
import {
  deleteMultipleService,
  reprocessMultipleService,
} from 'services/Dashboard-services';
import { Toast } from 'widgets/Toast/Toast';
import Loader from 'widgets/Loader/Loader';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

export const params = '';

function EnhancedTableToolbar({
  handleClick,
  selectedRows,
}: {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedRows: Number[];
}) {
  const { t } = useTranslation();

  function handleDelete() {
    try {
      const queryParams = new URLSearchParams();
      selectedRows.forEach((id) => {
        queryParams.append('coaching_session_reports_ids', String(id));
      });
      const params = String(queryParams);
      return params;
    } catch (error) {}
  }

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const dispatch = useAppDispatch();

  const [onDeleteSuccessModal, setOnDeleteSuccessModal] = useState(false);
  function handleOpenOnDeleteSuccessModal() {
    setOnDeleteSuccessModal(true);
  }
  function handleCloseOnDeleteSuccessModal() {
    setOnDeleteSuccessModal(false);
  }

  const handleDeleteProcessVideo = () => {
    const Payload = {
      log: `Session deleted`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));
    dispatch(
      deleteMultipleService(handleOpenOnDeleteSuccessModal, handleDelete)
    );
    handleCloseDeleteModal();
  };

  const { isLoadingProcessDataListVersion2, processDataListVersion2 } =
    useAppSelector(getProcessDataListVersion2Selector);
  const [processDataListWithSession, setProcessDataListWithSession] = useState<
    ProcessDataListVersion2[]
  >([]);

  const { SessionTypes } = useAppSelector(getSessionTypesSelector);

  useEffect(() => {
    const updatedProcessDataList = processDataListVersion2.map((item) => {
      const sessionInfo = SessionTypes.find(
        (session) => session.id === item.session_type_id
      );

      return {
        ...item,
        sessionName: sessionInfo ? sessionInfo.name : '',
      };
    });
    setProcessDataListWithSession(updatedProcessDataList);
  }, [processDataListVersion2, SessionTypes]);

  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);

  const videoAnalysisEnabled = false;
  // selectedSessionType.id === 59 ? getAIPermissions.video_analysis :
  const textAnalysisEnabled = getAIPermissions.text_analysis;

  const [reprocessVideoData, setReprocessVideoData] =
    useState<GetReprocessVideoServiceProps>({
      video_title: '',
      metrics_processing_events_history_id_list: -1,
      sessionDetails: {
        id: -1,
        name: '',
      },
      text_analysis: textAnalysisEnabled,
      video_analysis: videoAnalysisEnabled,
    });

  function handleReprocess(session_id: number) {
    try {
      const queryParams = new URLSearchParams();
      selectedRows.forEach((id) => {
        queryParams.append('cids', String(id));
      });
      const params = String(queryParams) + '&sid=' + session_id;
      return params;
    } catch (error) {}
  }

  const [openReprocessVideoModal, setReprocessVideoModal] =
    useState<boolean>(false);

  const handleOpenReprocessVideoModal = () => setReprocessVideoModal(true);

  const [
    openCancelledReprocessVideoModal,
    setOpenCancelledReprocessVideoModal,
  ] = useState(false);
  function handleOpenCancelledReprocessVideoModal() {
    setReprocessVideoModal(false);
    setOpenCancelledReprocessVideoModal(true);
    handleCloseReprocessChildVideoModal();
  }
  function handleCloseCancelledReprocessVideoModal() {
    setOpenCancelledReprocessVideoModal(false);
  }

  const [openSuccessReprocessVideoModal, setOpenSuccessReprocessVideoModal] =
    useState(false);
  function handleOpenSuccessReprocessVideoModal() {
    setOpenSuccessReprocessVideoModal(true);
  }
  function handleCloseSuccessReprocessVideoModal() {
    setOpenSuccessReprocessVideoModal(false);
  }

  const handleCloseReprocessVideoModal = () =>
    handleOpenCancelledReprocessVideoModal();
  const [openReprocessChildVideoModal, setReprocessChildVideoModal] =
    React.useState(false);

  const handleOpenReprocessChildVideoModal = () =>
    setReprocessChildVideoModal(true);

  const handleCloseReprocessChildVideoModal = () => {
    setReprocessChildVideoModal(false);
    setReprocessVideoModal(false);
  };

  const onReprocessConfirmation = () => {
    if (reprocessVideoData.sessionDetails.id === -1) {
      Toast(t('MyAssessments.SessionTypeIsRequired'), 'error');
    } else if (
      reprocessVideoData.video_title === '' &&
      selectedRows.length === 1
    ) {
      Toast(t('MyAssessments.VideoDescriptionIsRequired'), 'error');
    } else {
      const Payload = {
        log: `Session Reprocess Initiated`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));
      dispatch(
        reprocessMultipleService(
          reprocessVideoData,
          handleOpenSuccessReprocessVideoModal,
          handleReprocess
        )
      );
      handleCloseReprocessChildVideoModal();
    }
  };
  const { isLoadingReprocessVersion2Video } = useAppSelector(
    getReprocessVersion2VideoSelector
  );

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    if (selectedRows.length === 1) {
      const videoDataForReprocess = processDataListVersion2.find(
        (videoData) => videoData.coaching_session_reports_id === selectedRows[0]
      );

      if (videoDataForReprocess) {
        const sessionInfo = SessionTypes.find(
          (session) => session.id === videoDataForReprocess.session_type_id
        );

        const Payload = {
          log: `Session Reprocess Initiated`,
          log_type: 'info',
        };
        dispatch(AddAuditLogService(Payload));

        setReprocessVideoData({
          video_title: videoDataForReprocess
            ? videoDataForReprocess.session_title
            : '',
          metrics_processing_events_history_id_list: videoDataForReprocess
            ? videoDataForReprocess.coaching_session_reports_id
            : -1,
          sessionDetails: {
            id: sessionInfo ? sessionInfo.id : -1,
            name: sessionInfo ? sessionInfo.name : '',
          },
          text_analysis: textAnalysisEnabled,
          video_analysis: videoAnalysisEnabled,
        });
      }
    }
  }, [selectedRows]);

  return (
    <Toolbar
      sx={{
        p: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: '1 1 100%',
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
        }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {t('dashboard.MyRecordings')}
      </Typography>{' '}
      <Button
        variant='contained'
        startIcon={<ReprocessBulkIcon />}
        sx={{
          '&.Mui-disabled': {
            background: '#605E5E',
            color: 'white',
          },
          borderRadius: '25px',
          color: '#FFFFFF',
          backgroundColor: '#1206F9',
          fontWeight: 600,
          width: '100%',
          maxWidth: 115,
          marginRight: 2,
          marginLeft: 2,
          paddingLeft: 8,
          paddingRight: 8,
        }}
        id='basic-button'
        aria-haspopup='true'
        onClick={handleOpenReprocessVideoModal}
        disabled={selectedRows.length === 0}
      >
        {t('Reprocess')}
      </Button>
      <Button
        variant='outlined'
        startIcon={
          isActive ? <DeleteBulkActiveIcon /> : <DeleteBulkInactiveIcon />
        }
        sx={{
          '&.Mui-disabled': {
            color: '#605E5E',
            borderColor: '#605E5E',
          },
          borderRadius: '25px',
          color: '#EA3323',
          border: '1px solid #EA3323',
          fontWeight: 600,
          width: '100%',
          maxWidth: 115,
          marginRight: 2,
        }}
        id='basic-button'
        aria-haspopup='true'
        onClick={handleOpenDeleteModal}
        disabled={selectedRows.length === 0}
      >
        {t('Delete')}
      </Button>
      <Button
        variant='outlined'
        startIcon={<ColumnsListTableIcon />}
        sx={{
          borderRadius: '25px',
          background: '#fff',
          borderColor: '#1206f9',
          color: '#1206f9',

          fontWeight: 600,
          width: '100%',
          maxWidth: 115,
          marginRight: 2,
        }}
        id='basic-button'
        onClick={handleClick}
      >
        {t('dashboard.Columns')}
      </Button>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        onCloseDeleteModal={handleCloseDeleteModal}
        onConfirmDelete={handleDeleteProcessVideo}
        onCancelDelete={handleCloseDeleteModal}
      />
      <ConfirmationModal
        message={t('gallery.VideosDeletedSuccessfully')}
        openConfirmationModal={onDeleteSuccessModal}
        handleCloseConfirmationModal={handleCloseOnDeleteSuccessModal}
      />
      <NestedModal
        openReprocessVideoModal={openReprocessVideoModal}
        handleCloseReprocessVideoModal={handleCloseReprocessVideoModal}
        onReprocessConfirmation={onReprocessConfirmation}
        setReprocessVideoData={setReprocessVideoData}
        reprocessVideoData={reprocessVideoData}
        handleOpenReprocessChildVideoModal={handleOpenReprocessChildVideoModal}
        openReprocessChildVideoModal={openReprocessChildVideoModal}
        handleCloseReprocessChildVideoModal={
          handleOpenCancelledReprocessVideoModal
        }
        isBulkReprocess={selectedRows.length > 1}
      />
      <ConfirmationModal
        message={t('gallery.ReprocessCancelled')}
        openConfirmationModal={openCancelledReprocessVideoModal}
        handleCloseConfirmationModal={handleCloseCancelledReprocessVideoModal}
      />
      <ConfirmationModal
        message={t('gallery.FileIsBeingReprocessedCheckBackAfterSometime')}
        openConfirmationModal={openSuccessReprocessVideoModal}
        handleCloseConfirmationModal={handleCloseSuccessReprocessVideoModal}
      />
      {isLoadingReprocessVersion2Video && <Loader />}
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
