import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RecordedVideoPreview from 'components/Record/RecordedVideoPreview';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import getMediaDevices from 'utils/getMediaDevices';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import QuestionVideoPlayer from './QuestionVideoPlayer';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import QuestionnaireVideoIcon from 'icons/QuestionnaireVideoIcon';
import QuestionnaireAudioIcon from 'icons/QuestionnaireAudioIcon';
import QuestionnaireTextIcon from 'icons/QuestionnaireTextIcon';
import QuestionnaireVideoOffIcon from 'icons/QuestionnaireVideoOffIcon';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};
function PatientVideoRecorder({
  onRecordingComplete,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  Questionnaire,
  selectedVideoDevice,
  selectedAudioDevice,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
  isReUpload,
  clearBlobUrl,
  isUploaded,
  isCameraEnabled,
  isPlayed,
  setIsCameraEnabled,
}: {
  onRecordingComplete: (
    duration: number,
    isPrev: boolean,
    blobUrl?: Blob | MediaSource | string | null,
    TextAnswer?: string
  ) => void;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  Questionnaire: Array<QuestionnaireData>;
  selectedVideoDevice: MicrophoneDetails;
  selectedAudioDevice: CameraDetails;
  status: string;
  startRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: string;
  isReUpload: boolean;
  clearBlobUrl: () => void;
  isUploaded: boolean;
  isCameraEnabled: number;
  isPlayed: boolean;
  setIsCameraEnabled: React.Dispatch<React.SetStateAction<number>>;
}) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');

  const [recording, setRecording] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const totalSecondsRef = useRef<number>(0);
  const [TextAnswer, setResponseText] = useState<string | null>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording) {
      startRecording();
      setRecording(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [recording]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds((prev) => {
          const newTotal = prev + 1;
          totalSecondsRef.current = newTotal;
          return newTotal;
        });
      }, 1000);

      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    }

    if (status === 'stopped') {
      pauseTimer();
    }
  }, [status]);

  const startTimer = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = () => {
    setIsRunning(false);
    setTotalSeconds(0);
    totalSecondsRef.current = 0;
    setMinutes(0);
    setSeconds(0);
  };

  const handlePreviousQuestion = async () => {
    const recordedSeconds = totalSecondsRef.current;
    if (isCameraEnabled !== 2) {
      if (mediaBlobUrl) {
        onRecordingComplete(recordedSeconds, true, mediaBlobUrl);
        clearBlobUrl();
        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

          return newIndex;
        });
      }
    } else {
      if (TextAnswer && TextAnswer.trim() !== '' && textFieldRef.current) {
        onRecordingComplete(recordedSeconds, false, undefined, TextAnswer);
        textFieldRef.current.value = '';
        setResponseText(null);

        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

          return newIndex;
        });
      }
    }
  };

  const handleStopClick = async () => {
    if (status === 'recording') {
      await stopRecording();
    }
  };

  const handleStopRecording = async () => {
    if (status === 'recording') {
      stopRecording();
    }

    const recordedSeconds = totalSecondsRef.current;

    if (isCameraEnabled !== 2) {
      if (mediaBlobUrl) {
        onRecordingComplete(recordedSeconds, false, mediaBlobUrl);
        clearBlobUrl();
        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          if (prevIndex < Questionnaire.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }
    } else {
      if (TextAnswer && TextAnswer.trim() !== '' && textFieldRef.current) {
        onRecordingComplete(recordedSeconds, false, undefined, TextAnswer);
        textFieldRef.current.value = '';
        setResponseText(null);

        resetTimer();
      } else {
        setCurrentQuestionIndex((prevIndex) => {
          if (prevIndex < Questionnaire.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }
    }
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    let stream: MediaStream | null = null;

    if (selectedVideoDevice) {
      if (isCameraEnabled === 0) {
        navigator.mediaDevices
          .getUserMedia({
            video: { deviceId: selectedVideoDevice.deviceId },
            audio: true,
          })
          .then((mediaStream) => {
            stream = mediaStream;
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
              videoRef.current.muted = true;
            }
          })
          .catch((error) => {});
      } else if (isCameraEnabled === 1) {
        if (videoRef.current && videoRef.current.srcObject) {
          const tracks = (
            videoRef.current.srcObject as MediaStream
          ).getTracks();
          tracks.forEach((track) => track.stop());
          videoRef.current.srcObject = null;
        }
      }
    }

    return () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [selectedVideoDevice, isCameraEnabled]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number | null
  ) => {
    if (newAlignment !== null) {
      setIsCameraEnabled(newAlignment);
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponseText(JSON.stringify(event.target.value));
  };

  const MemoizedVideoPreview = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          mb: isCameraEnabled === 2 ? 2 : 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '8px',
            padding: '10px',
            zIndex: 10,
            gap: '10px',
            position: isCameraEnabled === 2 ? 'relative' : 'absolute',
            top: isCameraEnabled === 2 ? '0' : '10px',
            left: isCameraEnabled === 2 ? '0' : '10px',
            width: isCameraEnabled === 2 ? 'fit-content' : 'auto',
          }}
        >
          <Typography
            label=' Select your response mode'
            sx={{ fontSize: '10px', color: '#E0E0E0' }}
          />

          <ToggleButtonGroup
            value={isCameraEnabled}
            exclusive
            onChange={handleAlignment}
            disabled={status === 'recording'}
          >
            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 0 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 0 ? '#1206f9' : '#fff',
                },
              }}
              value={0}
              aria-label='Video'
            >
              <QuestionnaireVideoIcon isSelected={isCameraEnabled === 0} />
            </ToggleButton>

            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 1 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 1 ? '#1206f9' : '#fff',
                },
              }}
              value={1}
              aria-label='Audio'
            >
              <QuestionnaireAudioIcon isSelected={isCameraEnabled === 1} />
            </ToggleButton>

            <ToggleButton
              sx={{
                backgroundColor:
                  isCameraEnabled === 2 ? '#1206f9 !important' : '#fff',
                '&:hover': {
                  backgroundColor: isCameraEnabled === 2 ? '#1206f9' : '#fff',
                },
              }}
              value={2}
              aria-label='Text'
            >
              <QuestionnaireTextIcon isSelected={isCameraEnabled === 2} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ position: 'relative' }}>
          {isCameraEnabled === 0 ? (
            <video
              ref={videoRef}
              id='MemoizedVideoPreview'
              autoPlay
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                aspectRatio: 16 / 9,
                objectFit: 'cover',
                transform: 'scaleX(-1)',
              }}
            />
          ) : isCameraEnabled === 1 ? (
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: 'black',
                minHeight: '55vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <QuestionnaireVideoOffIcon />
            </Box>
          ) : isCameraEnabled === 2 ? (
            <Box sx={{ width: '100%', maxHeight: '40vh', height: 'auto' }}>
              <TextField
                id='Chat_Text_field'
                label='Write Your Response'
                multiline
                rows={11}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                  '& .MuiInputLabel-root.MuiFormLabel-filled': {
                    color: '#1206f9',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    mb: 1,
                  },
                }}
                fullWidth
                placeholder=''
                variant='filled'
                onChange={handleTextChange}
                inputRef={textFieldRef}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }, [status, mediaBlobUrl, isCameraEnabled]);

  return (
    <Paper sx={{ height: '100%' }}>
      <Box sx={{ Height: '70%' }}>{MemoizedVideoPreview}</Box>{' '}
      <Box
        sx={{
          width: '95%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          p: 1,
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Button
            ref={nextButtonRef}
            sx={{ color: '#1206F9' }}
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 1 || status === 'recording'}
          >
            Prev
          </Button>
          {isCameraEnabled !== 2 && (
            <>
              {status === 'recording' ? (
                <Button
                  sx={{
                    backgroundColor: '#1206F9',
                    borderRadius: '30px',
                    border: '1px solid #1206F9',
                  }}
                  variant='contained'
                  startIcon={<StopRecordingIcon />}
                  onClick={handleStopClick}
                  id='stopRecordingButton'
                >
                  Stop
                </Button>
              ) : (
                <Box>
                  {status === 'acquiring_media' ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      onClick={startRecording}
                      sx={{
                        backgroundColor:
                          isReUpload || mediaBlobUrl ? '#FFF' : '#1206F9',
                        color: isReUpload || mediaBlobUrl ? '#1206f9' : '#FFF',
                        borderRadius: '30px',
                        textTransform: 'none',
                      }}
                      size='small'
                      variant={
                        isReUpload || mediaBlobUrl ? 'text' : 'contained'
                      }
                      startIcon={<StartAssessmentRecordIcon />}
                      id='startRecordingButton'
                    >
                      {isReUpload || mediaBlobUrl
                        ? 'Re-record My Response'
                        : 'Record My Response'}
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}
          <Button
            sx={{
              color:
                isReUpload || mediaBlobUrl || TextAnswer ? '#FFF' : '#1206f9',
              backgroundColor:
                isReUpload || mediaBlobUrl || TextAnswer
                  ? '#1206f9'
                  : 'transparent',
              borderRadius: '20px',
              '&:hover': {
                color:
                  isReUpload || mediaBlobUrl || TextAnswer ? '#FFF' : '#1206f9',
                backgroundColor:
                  isReUpload || mediaBlobUrl || TextAnswer
                    ? '#1206f9'
                    : 'transparent',
              },
            }}
            ref={nextButtonRef}
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleStopRecording}
            variant={isReUpload || mediaBlobUrl ? 'contained' : 'text'}
            disabled={
              status === 'recording' || isCameraEnabled === 2
                ? !isUploaded && !TextAnswer
                : !isUploaded && !mediaBlobUrl
            }
            id={
              currentQuestionIndex === Questionnaire.length - 1
                ? 'FinishButton'
                : 'NextButton'
            }
          >
            {currentQuestionIndex === Questionnaire.length - 1
              ? 'Finish'
              : 'Next'}
          </Button>
        </Box>

        {/* status TBD             <Typography
          label=''
          sx={{
            position: isMobileOrTablet ? '' : 'absolute',
            right: isMobileOrTablet ? '' : '20px',
            top: isMobileOrTablet ? '' : '50%',
            transform: isMobileOrTablet ? '' : 'translateY(-50%)',
            fontSize: 12,
            mt: isMobileOrTablet ? 1 : 0,
            animation:
              status === 'recording' ? 'blinker 2s linear infinite' : '',
            '@keyframes blinker': {
              '50%': { opacity: 0 },
            },
          }}
        >
          {status}
        </Typography> */}
      </Box>
    </Paper>
  );
}

export default PatientVideoRecorder;
