/** @format */
import { Box } from '@mui/material';
import RecordVideoButtonIcon from 'icons/RecordVideoButtonIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { UploadSingleFileService } from 'services/UploadMedia-services';
import Button from 'widgets/CustomButton/Button';
import { removeFilesFromArray } from 'utils/removeFilesFromArray';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { featureFlagList } from 'constants/FeatureFlags';
import { getAIPermissionsSelector } from 'redux/selectors/selectors';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

interface FileWithPreview extends File {
  preview: string;
}

interface UseFilesProps {
  initialState?: FileWithPreview[];
  maxFiles: number;
}

interface IosRecordVideoProps {
  videoDesc: string;
  recordingType: { id: number };
  selectedSessionType: {
    id: number;
  };
  handleOpenRecordedSessionUploadCompleteModal: () => void;
}

function removeItems<T>(arr: T[], item: number): void {
  for (let i = 0; i < item; i++) {
    arr.pop();
  }
}

function useFiles({ initialState = [], maxFiles }: UseFilesProps) {
  const [state, setState] = useState<FileWithPreview[]>(initialState);

  function withBlobs(files: File[]) {
    const destructured = [...files];

    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeItems(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        const fileWithPreview = file as FileWithPreview;
        fileWithPreview.preview = URL.createObjectURL(file);
        return fileWithPreview;
      })
      .filter((elem) => elem !== null);

    setState(blobs);
  }

  return [state, withBlobs] as const;
}

function IosRecordVideo({
  videoDesc,
  recordingType,
  selectedSessionType,
  handleOpenRecordedSessionUploadCompleteModal,
}: IosRecordVideoProps) {
  const maxFiles = 1;
  const $input = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const { isFeatureEnable } = useFeatureFlag();
  const [files, setFiles] = useFiles({ maxFiles });
  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);

  useEffect(() => {
    if (files.length > 0) {
      handleUpload();
    }
  }, [files]);

  const handleUploadComplete = useCallback(() => {
    setFiles([]);
    handleOpenRecordedSessionUploadCompleteModal();
  }, [handleOpenRecordedSessionUploadCompleteModal]);

  const videoAnalysisEnabled = false;
  const textAnalysisEnabled =
    selectedSessionType.id === 59 ? getAIPermissions.text_analysis : false;

  const handleUpload = useCallback(async () => {
    if (files.length > 0) {
      const Payload = {
        log: `Recorded video upload started`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));
      dispatch(
        UploadSingleFileService(
          files[0].preview,
          selectedSessionType.id,
          videoDesc,
          recordingType,
          handleUploadComplete,
          isFeatureEnable(featureFlagList.dynamicPrediction),
          videoAnalysisEnabled,
          textAnalysisEnabled
        )
      );
    }
  }, [
    dispatch,
    files,
    selectedSessionType.id,
    videoDesc,
    recordingType,
    handleUploadComplete,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
      }}
    >
      <Button
        sx={{
          padding: 0.5,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 5,
          backgroundColor: '#1206f9',
          fontSize: '10px',
        }}
        variant='contained'
        startIcon={<RecordVideoButtonIcon />}
        className='custom-file-button'
        onClick={() => {
          if ($input.current) {
            $input.current.click();
          }
        }}
        label='record.ClickToOpenCameraAndStartRecording'
      />
      <input
        type='file'
        ref={$input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newFiles = Array.from(e.target.files || []);
          setFiles([...files, ...newFiles]);
        }}
        multiple={maxFiles > 1}
        accept='audio/*, video/*'
        capture='user'
        style={{ display: 'none' }}
      />
    </Box>
  );
}

export default IosRecordVideo;
