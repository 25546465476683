import * as React from 'react';
import { TempPromptQuestionsArrayType } from 'types/MyAssessments-types';
import Box from '@mui/material/Box';
import Typography from 'widgets/Typography/Typography';

export default function PromptsAccordation({
  practiseSections,
}: {
  practiseSections: TempPromptQuestionsArrayType[];
}) {
  return (
    <div>
      {practiseSections.map(
        (section: TempPromptQuestionsArrayType, index: number) => {
          return (
            <Box
              key={section.id}
              sx={{
                backgroundColor: 'transparent',
                p: 2,
                borderBottom: '1px solid #CAC4D0',
                borderRadius: '4px',
                display: 'block',
                gap: 1,
              }}
            >
              <Typography
                label={`Prompt ${index + 1}`}
                sx={{ color: '#8D8D8D', fontSize: '14px', fontWeight: 500 }}
              />
              <Typography
                label={section.question}
                sx={{ color: '#33344B', fontSize: '14px', fontWeight: 400 }}
              />
            </Box>
          );
        }
      )}
    </div>
  );
}
