/** @format */

import {
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { AdminDrawerItemsArray, DrawerItemsArray } from './DrawerItemsArray';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { featureFlagList } from 'constants/FeatureFlags';
import { useAppDispatch } from 'redux/store';
import { Payload } from '../../types/DeepRecruiterTypes/SelectAssessment-types';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function RenderMenuList({ open }: { open: boolean | undefined }) {
  const history = useHistory();
  const isUserLoggedInAdmin = sessionStorage.getItem('userRole') === 'Admin';
  const { t } = useTranslation();
  const { isFeatureEnable } = useFeatureFlag();
  const dispatch = useAppDispatch();

  const handleRouting = (item: {
    id: string;
    text: string;
    icon: React.JSX.Element;
    route: string;
  }) => {
    const Payload = {
      log: `${t(`${item.text}`)} Accessed`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));
    history.push(item.route);
  };

  return (
    <List>
      {DrawerItemsArray.map((item, index) => {
        if (
          item.id === 'myAssessmentsIcon' &&
          !isFeatureEnable(featureFlagList.myAssessments)
        ) {
          return null;
        }

        return (
          <ListItem
            id={`drawerItem${item.id.toString()}`}
            onClick={() => {
              handleRouting(item);
            }}
            key={index}
            disablePadding
            sx={{ display: 'block' }}
            role='listitem'
            aria-label={`${t(item.text)}`}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title={`${t(item.text)}`} role='tooltip'>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                    width: '40px',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t(item.text)}
                sx={{
                  color: '#33344b',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 400,
                  opacity: open ? 0.8 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}

      {isUserLoggedInAdmin &&
        AdminDrawerItemsArray.map((item, index) => {
          return (
            <ListItem
              id={`drawerAdminItem${item.id.toString()}`}
              onClick={() => {
                handleRouting(item);
              }}
              key={index}
              disablePadding
              sx={{ display: 'block' }}
              role='listitem'
              aria-label={`${t(item.text)}`}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <Tooltip title={`${t(item.text)}`} role='tooltip'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                      width: '40px',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t(item.text)}
                  sx={{
                    color: '#33344b',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    opacity: open ? 0.8 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}

export default RenderMenuList;
