/** @format */
export const AdminRoutes = {
  featureRoute: '/feature',
  billingRoute: '/billing',
};

export const ProtectedRoutes = {
  dashboardRoute: '/',
  galleryRoute: '/gallery',
  learningsRoute: '/learnings',
  subscriptionsRoute: '/subscriptions',
  helpRoute: '/help',
  recordRoute: '/record',
  feedback: '/feedback',
  uploadRoute: '/upload',
  videoPreviewAdvance: '/videopreviewadvance',
  profileRoute: '/profile',
  StripeSubscriptionPaymentFail: '/StripeSubscriptionPaymentFail',
  StripeSubscriptionPaymentSuccess: '/StripeSubscriptionPaymentSuccess',
  myQuestionnaireRoute: '/myQuestionnaire',
  SendQuestionnaireRoute: '/sendQuestionnaire',
  SandboxVideoPreview: '/preview/mediapreview',
  SandboxGallery: '/preview/galleryPreview',
};

export const OpenRoutes = {
  signInRoute: '/login',
  signUpRoute: '/signup',
  forgotPasswordRoute: '/forgotPassword',
  UpdateForgottenPasswordRoute: '/ResetPassword',
  signUpSuccessPage: '/Confirmation',
  IframeVideoControlRoute: '/IFrameVideoPreviewAdvance',
  IframeDashboardRoute: '/IframeDashboard',
  candidateAssessmentRoute: '/candidateQuestionnaire',
  MirroSandboxRoute: '/preview',
  QuestionnaireRoute: '/Questionnaire',
  TeleHealthDashboard: '/dashboard',
};

export const deepCandidateOpenRoutes = {
  DCSignUPRoute: '/DCSignup',
  DCSignInRoute: '/DCSignin',
};
