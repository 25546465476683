/** @format */

import React, { useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Container,
  Divider,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch } from 'redux/store';
import { getUpdatePasswordService } from 'services/UserDetails-service';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function ResetPasswordComponent() {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [currentPasswordError, setCurrentPasswordError] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] =
    useState<string>('');
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const handleTogglePasswordVisibility = (field: string) => {
    switch (field) {
      case 'currentPassword':
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmNewPassword':
        setShowConfirmNewPassword(!showConfirmNewPassword);
        break;
      default:
        break;
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPasswordValue = e.target.value;
    setConfirmNewPassword(confirmPasswordValue);

    if (newPassword !== confirmPasswordValue) {
      setConfirmNewPasswordError(
        t('updatePassword.NewPasswordAndConfirmPasswordDoNotMatch')
      );
    } else {
      setConfirmNewPasswordError('');
    }
  };

  const handleSubmit = () => {
    if (!currentPassword) {
      setCurrentPasswordError(t('updatePassword.CurrentPasswordIsRequired'));
      return;
    } else {
      setCurrentPasswordError('');
    }

    if (!newPassword) {
      setNewPasswordError(t('updatePassword.NewPasswordIsRequired'));
      return;
    } else {
      setNewPasswordError('');
    }

    if (!confirmNewPassword) {
      setConfirmNewPasswordError(
        t('updatePassword.ConfirmNewPasswordIsRequired')
      );
      return;
    } else {
      setConfirmNewPasswordError('');
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError(
        t('updatePassword.NewPasswordAndConfirmPasswordDoNotMatch')
      );
      return;
    }
    const Payload = {
      log: `Password reseted suceessfully`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));

    dispatch(getUpdatePasswordService(currentPassword, newPassword));
    setConfirmNewPassword('');
    setCurrentPassword('');
    setNewPassword('');
  };
  return (
    <Container
      id='updatepassword'
      component='main'
      maxWidth='sm'
      sx={{
        mt: isMobile || isTablet ? 0 : 3,
        ml: isMobile || isTablet ? 0 : 36.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
      }}
    >
      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '1.5rem',
          letterSpacing: '-0.012rem',
          marginTop: 2,
        }}
        label='updatePassword.UpdatePassword'
      />

      <Typography
        sx={{ maxWidth: '37.5rem' }}
        label='updatePassword.TheMostSecurePasswordsAreAtleast8CharactersLongAndIncludeAMixOfLettersNumbersAndSymbols'
      />

      <TextField
        name='password'
        sx={{ marginTop: 5, width: isMobile || isTablet ? '100%' : '65%' }}
        id='Current Password'
        label={t('updatePassword.CurrentPassword')}
        variant='outlined'
        value={currentPassword}
        type={showCurrentPassword ? 'text' : 'password'}
        onChange={(e) => setCurrentPassword(e.target.value)}
        error={currentPasswordError !== ''}
        helperText={currentPasswordError}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={() =>
                  handleTogglePasswordVisibility('currentPassword')
                }
              >
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        name='password'
        sx={{ marginTop: 5, width: isMobile || isTablet ? '100%' : '65%' }}
        id='New_Password'
        label={t('updatePassword.NewPassword')}
        variant='outlined'
        value={newPassword}
        type={showNewPassword ? 'text' : 'password'}
        onChange={(e) => setNewPassword(e.target.value)}
        error={newPasswordError !== ''}
        helperText={newPasswordError}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={() => handleTogglePasswordVisibility('newPassword')}
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        name='password'
        sx={{ marginTop: 5, width: isMobile || isTablet ? '100%' : '65%' }}
        id='ConfirmNewPassword'
        label={t('updatePassword.ConfirmPassword')}
        variant='outlined'
        type={showConfirmNewPassword ? 'text' : 'password'}
        value={confirmNewPassword}
        onChange={handleConfirmPasswordChange}
        error={confirmNewPasswordError !== ''}
        helperText={confirmNewPasswordError}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={() =>
                  handleTogglePasswordVisibility('confirmNewPassword')
                }
              >
                {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Divider sx={{ marginTop: 6 }} />
      <Box>
        <Button
          id='saveChangesButton'
          variant='contained'
          onClick={handleSubmit}
          sx={{
            borderRadius: '5rem',
            background: '#1206F9',
            float: 'right',
            width: 'fit-content',
            height: 'fit-content',
            mt: 2,
            color: '#fff',
          }}
          label='profile.SaveChanges'
        />
      </Box>
    </Container>
  );
}

export default ResetPasswordComponent;
