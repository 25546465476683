import React from 'react';

function CopyLinkIcon() {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.125 5V12.875C12.125 14.5325 10.7825 15.875 9.125 15.875C7.4675 15.875 6.125 14.5325 6.125 12.875V5C6.125 3.965 6.965 3.125 8 3.125C9.035 3.125 9.875 3.965 9.875 5V12.125C9.875 12.5375 9.5375 12.875 9.125 12.875C8.7125 12.875 8.375 12.5375 8.375 12.125V5H7.25V12.125C7.25 13.16 8.09 14 9.125 14C10.16 14 11 13.16 11 12.125V5C11 3.3425 9.6575 2 8 2C6.3425 2 5 3.3425 5 5V12.875C5 15.155 6.845 17 9.125 17C11.405 17 13.25 15.155 13.25 12.875V5H12.125Z'
        fill='#1906F9'
      />
    </svg>
  );
}

export default CopyLinkIcon;
