import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import {
  Processing_Status,
  Processing_is_in_progress,
  Summary_Status,
  Exception_error_Summary,
} from 'constants/videoPreview';
import React, { useEffect, useMemo, useState } from 'react';
import { CoachingSessionReport, SectionConfig } from 'types/VideoPreview-types';
import { Toast } from 'widgets/Toast/Toast';
import Typography from 'widgets/Typography/Typography';

type professional_summaryKeys =
  | 'key_skills'
  | 'industry_knowledge'
  | 'achievements';
type key_discrepanciesKeys =
  | 'skill_discrepancies'
  | 'experience_discrepancies'
  | 'confidence_patterns';

type areas_of_concernKeys = 'areas_of_concern' | 'strengths_validated';

type career_trajectoryKeys =
  | 'career_goals'
  | 'preferred_work_environment'
  | 'growth_areas';

type development_recommendationsKeys =
  | 'training_needs'
  | 'certification_paths'
  | 'mentorship_areas'
  | 'career_advancement_steps';

const development_recommendationsKeys: Record<
  string,
  development_recommendationsKeys
> = {
  'Training Needs': 'training_needs',
  'Certification Paths': 'certification_paths',
  'Mentorship Areas': 'mentorship_areas',
  'Career Advancement Steps': 'career_advancement_steps',
};

const career_trajectoryKeys: Record<string, career_trajectoryKeys> = {
  'Career Goals': 'career_goals',
  'Preferred Work Environment': 'preferred_work_environment',
  'Growth Areas': 'growth_areas',
};

const areas_of_concernKeys: Record<string, areas_of_concernKeys> = {
  'Areas of Concern': 'areas_of_concern',
  'Strengths Validated': 'strengths_validated',
};

const key_discrepanciesKeys: Record<string, key_discrepanciesKeys> = {
  'Skill Discrepancies': 'skill_discrepancies',
  'Experience Discrepancies': 'experience_discrepancies',
  'Confidence Patterns': 'confidence_patterns',
};

const professional_summaryKeys: Record<string, professional_summaryKeys> = {
  'key skills': 'key_skills',
  'Industry Knowledge': 'industry_knowledge',
  achievements: 'achievements',
};

function InterviewSummary({
  ProcessDataListForParticipantsVersion2,
  isEditing,
  showOriginal,
  editedContent,
  setEditedContent,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  isEditing: boolean;
  showOriginal: boolean;
  editedContent: Record<string, string>;
  setEditedContent: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}) {
  const firstParticipant = ProcessDataListForParticipantsVersion2?.[0];
  const detailedSummaryLoad = firstParticipant?.detailed_summary;

  const isDetailedSummaryEmpty =
    detailedSummaryLoad && Object.keys(detailedSummaryLoad).length === 0;

  const sectionsConfig: SectionConfig[] = useMemo(() => {
    if (isDetailedSummaryEmpty && firstParticipant.process_status !== 13) {
      return [
        {
          label: `${Processing_Status}`,
          rows: 1,
          value: `${Processing_is_in_progress}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    if (isDetailedSummaryEmpty && firstParticipant.process_status === 13) {
      return [
        {
          label: `${Summary_Status}`,
          rows: 1,
          value: `${Exception_error_Summary}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    return showOriginal
      ? [
          {
            label: 'Professional Summary',
            rows: 18,
            value:
              Object.keys(professional_summaryKeys)
                .map((sectionTitle) => {
                  const key = professional_summaryKeys[sectionTitle];
                  const items =
                    ProcessDataListForParticipantsVersion2?.[0]
                      ?.detailed_summary?.recruitment_analysis
                      ?.professional_summary?.[key] || [];

                  if (Array.isArray(items)) {
                    return `${sectionTitle}:\n${items.join('\n')}\n`;
                  } else {
                    return '';
                  }
                })
                .join('\n') +
              `\nExperience Level: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.professional_summary
                  ?.experience_level || ''
              }\n`,
            onChangeKey: 'professional_summary',
          },
          {
            label: 'Emotional Analysis',
            rows: 20,
            value:
              `Statement: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.emotional_analysis
                  ?.tone_confidence_mapping[0]?.statement || ''
              }\n` +
              `Emotional Tone: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.emotional_analysis
                  ?.tone_confidence_mapping[0]?.emotional_tone || ''
              }\n` +
              `Confidence Score: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.emotional_analysis
                  ?.tone_confidence_mapping[0]?.confidence_score || ''
              }\n` +
              Object.keys(key_discrepanciesKeys)
                .map((sectionTitle) => {
                  const key = key_discrepanciesKeys[sectionTitle];
                  const items =
                    ProcessDataListForParticipantsVersion2?.[0]
                      ?.detailed_summary?.recruitment_analysis
                      ?.emotional_analysis?.key_discrepancies?.[key] || [];

                  if (Array.isArray(items)) {
                    return `${sectionTitle}:\n${items.join('\n')}\n`;
                  } else {
                    return '';
                  }
                })
                .join('\n') +
              `\nConsistency Score : ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.emotional_analysis
                  ?.overall_authenticity_assessment?.consistency_score || ''
              }\n` +
              Object.keys(areas_of_concernKeys)
                .map((sectionTitle) => {
                  const key = areas_of_concernKeys[sectionTitle];
                  const items =
                    ProcessDataListForParticipantsVersion2?.[0]
                      ?.detailed_summary?.recruitment_analysis
                      ?.emotional_analysis?.overall_authenticity_assessment?.[
                      key
                    ] || [];

                  if (Array.isArray(items)) {
                    return `${sectionTitle}:\n${items.join('\n')}\n`;
                  } else {
                    return '';
                  }
                })
                .join('\n'),
            onChangeKey: 'emotional_analysis',
          }, //
          {
            label: 'Career Trajectory',
            rows: 18,
            value:
              `Current Role: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.recruitment_analysis?.career_trajectory?.current_role || ''
              }\n` +
              Object.keys(career_trajectoryKeys)
                .map((sectionTitle) => {
                  const key = career_trajectoryKeys[sectionTitle];
                  const items =
                    ProcessDataListForParticipantsVersion2?.[0]
                      ?.detailed_summary?.recruitment_analysis
                      ?.career_trajectory?.[key] || [];

                  if (Array.isArray(items)) {
                    return `${sectionTitle}:\n${items.join('\n')}\n`;
                  } else {
                    return '';
                  }
                })
                .join('\n'),
            onChangeKey: 'career_trajectory',
          },
          {
            label: 'Development Recommendations',
            rows: 18,
            value: Object.keys(development_recommendationsKeys)
              .map((sectionTitle) => {
                const key = development_recommendationsKeys[sectionTitle];
                const items =
                  ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                    ?.recruitment_analysis?.development_recommendations?.[
                    key
                  ] || [];

                if (Array.isArray(items)) {
                  return `${sectionTitle}:\n${items.join('\n')}\n`;
                } else {
                  return '';
                }
              })
              .join('\n'),
            onChangeKey: 'development_recommendations',
          },
        ]
      : [
          {
            label: 'Professional Summary',
            rows: 18,
            value: `${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.professional_summary || ''
            }\n`,
            onChangeKey: 'professional_summary',
          },
          {
            label: 'Emotional Analysis',
            rows: 20,
            value: `${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.emotional_analysis || ''
            }\n`,
            onChangeKey: 'emotional_analysis',
          },
          {
            label: 'Career Trajectory',
            rows: 18,
            value: `${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.career_trajectory || ''
            }\n`,
            onChangeKey: 'career_trajectory',
          },
          {
            label: 'Development Recommendations',
            rows: 18,
            value: `${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.development_recommendations || ''
            }\n`,
            onChangeKey: 'development_recommendations',
          },
        ];
  }, [showOriginal, ProcessDataListForParticipantsVersion2]);

  useEffect(() => {
    setEditedContent(
      sectionsConfig.reduce((acc, section) => {
        acc[section.onChangeKey] = section.value;
        return acc;
      }, {} as Record<string, string>)
    );
  }, [sectionsConfig]);

  const handleTextFieldChange = (key: string, newValue: string) => {
    setEditedContent((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleCopy = () => {
    const allDataToCopy = Object.values(sectionsConfig)
      .map((section) => section.value)
      .join('\n\n');
    if (allDataToCopy) {
      navigator.clipboard.writeText(allDataToCopy);
      Toast('Content copied successfully!', 'success');
    } else {
      Toast('Failed to copy', 'error');
    }
  };
  return (
    <Box>
      {isEditing ? (
        <div>
          {sectionsConfig.map((section, index) => (
            <section key={index} style={{ marginTop: 2, marginBottom: 2 }}>
              <Typography label={section.label} sx={{ mt: 1, mb: 1 }} />
              <TextField
                multiline
                rows={section.rows}
                value={editedContent[section.onChangeKey]}
                onChange={(e) =>
                  handleTextFieldChange(section.onChangeKey, e.target.value)
                }
                fullWidth
                variant='outlined'
                sx={{ fontSize: '14px' }}
              />
            </section>
          ))}
        </div>
      ) : (
        <>
          {sectionsConfig.map((section, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '4px',
                p: 1,
                maxWidth: '100%',
                breakBefore: 'page',
                breakInside: 'avoid',
                pageBreakBefore: 'always',
              }}
            >
              <Typography label='' sx={{ fontWeight: 600, fontSize: '16px' }}>
                {section.label}
              </Typography>
              <Typography
                label=''
                sx={{
                  fontSize: '14px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  maxWidth: '100%',
                  display: 'block',
                  breakBefore: 'page',
                  breakInside: 'avoid',
                  pageBreakBefore: 'always',
                }}
              >
                {section.value || 'N/A'}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}

export default InterviewSummary;
