import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputBase,
  Paper,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getPractiseSectionsSelector,
  getQuestionnairePractiseSectionsSelector,
  getSessionTypesSelector,
  sendAssessmentEmailToCandidatesSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getQuestionnaireSessionTypesServiceApi,
  getSessionTypesServiceApi,
} from 'services/UploadMedia-services';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import PromptsModal from './PromptsModal';
import AddEmailComponent from './AddEmailComponent';
import { Toast } from 'widgets/Toast/Toast';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';

import Loader from 'widgets/Loader/Loader';
import { sendAssessmentEmailToCandidatesServiceApi } from 'services/SendAssessments-service';
import env from 'envConfig';
import { UploadMediaData } from 'types/Upload-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyLinkSettingsModal from './CopyLinkSettingsModal';
import { useTranslation } from 'react-i18next';
import GlobalSearchIcon from 'icons/GlobalSearchIcon';
import { getPractiseSectionsServiceApi } from 'services/MyAssessment-service';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PromptsAccordation from './PromptsAccordation';
import CopyLinkIcon from 'icons/CopyLinkIcon';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#308fe8' : '#1a90ff',
  },
}));

const linkExpiryHrs = 24;

function SendCandidateAssessments() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { SessionTypes, isLoadingSessionTypes } = useAppSelector(
    getQuestionnairePractiseSectionsSelector
  );
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [currentlySelectedViewPromptsId, setCurrentlySelectedViewPromptsId] =
    useState(-1);
  const [filteredSessions, setFilteredSessions] = useState(SessionTypes);
  const { practiseSections } = useAppSelector(getPractiseSectionsSelector);
  const [questionnaireSearchValue, setQuestionnaireSearchValueSearchValue] =
    React.useState('');

  const [selectedAssessmentType, setSelectedAssessmentType] = useState<{
    id: number;
    name: string;
    type?: string;
    description?: string;
  }>({
    id: -1,
    name: '',
    type: '',
    description: '',
  });

  const handleSetAssementType = useCallback(
    (newValue: {
      id: number;
      name: string;
      type?: string;
      description?: string;
    }) => {
      if (newValue && typeof setSelectedAssessmentType === 'function') {
        setSelectedAssessmentType(newValue);
      }
    },
    [setSelectedAssessmentType]
  );

  useEffect(() => {
    dispatch(getQuestionnaireSessionTypesServiceApi(-1, true));
    setLoading(true);
  }, []);

  useEffect(() => {
    if (SessionTypes.length > 0) {
      setFilteredSessions(SessionTypes);
      setLoading(false);
    }
  }, [SessionTypes]);

  React.useEffect(() => {
    if (SessionTypes.length > 0) {
      setSelectedAssessmentType(SessionTypes[0]);
      setLoading(false);
    }
  }, [SessionTypes]);
  React.useEffect(() => {
    if (selectedAssessmentType?.id !== -1) {
      dispatch(getPractiseSectionsServiceApi(selectedAssessmentType.id));
    }
  }, [selectedAssessmentType]);

  const handleClosePromptsModal = () => {
    setOpen(false);
  };

  const [candidateEmailList, setCandidateEmailList] = useState<string[]>([]);

  const [email, setEmail] = useState('');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  function handleAddCandidate(email: string) {
    if (
      candidateEmailList.includes(email.trim().toLowerCase().replace(/\s/g, ''))
    ) {
      Toast(t('SendAssessments.EmailAlreadyExistsInTheList'), 'error');
      return;
    }

    setCandidateEmailList((prevList: string[]) => {
      return [...prevList, email.trim().toLowerCase().replace(/\s/g, '')];
    });
    setEmail('');
  }
  function handleRemoveCandidate(email: string) {
    const updatedList = candidateEmailList.filter(
      (candidateEmail) => candidateEmail !== email
    );
    setCandidateEmailList(updatedList);
  }

  // const origin = window.location.origin;

  function handleSendAssessmentInvitationToCandidate() {
    if (selectedAssessmentType.id === -1) {
      Toast(t('SendAssessments.PleaseSelectAssessmentType'), 'error');
    } else {
      const utcDateTimeString = new Date(
        new Date().getTime() + linkExpiryHrs * 60 * 60 * 1000
      ).toISOString();

      const arrayToEncrypt = {
        senderApiKey: sessionStorage.getItem('apiKey')
          ? sessionStorage.getItem('apiKey')
          : '',
        selectedAssessmentType: selectedAssessmentType,
        expirationDateTimeStampInUtc: utcDateTimeString,
      };

      const encryptedArray = btoa(JSON.stringify(arrayToEncrypt));
      const link = `${window.location.origin}/#/candidateQuestionnaire?token=${encryptedArray}`;
      const payload = {
        candidateAssessmentLinkRedirect: link,
        email_ids: candidateEmailList,
        mirroLogoPath:
          env.REACT_APP_BASE_URL + '/comonImg/mirro-logo-with-namee.png',
        mirroBannerPath:
          env.REACT_APP_BASE_URL + '/comonImg/mirroBannerImage.png',
        hrsToComplete: linkExpiryHrs.toString(),
      };

      const Payload = {
        log: `Questionnaire Sent`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));

      dispatch(sendAssessmentEmailToCandidatesServiceApi(payload));
    }
  }
  const [
    currentlySelectedAssessmentToSendvalue,
    setCurrentlySelectedAssessmentToSendvalue,
  ] = React.useState(-1);

  const handleBoxClick = (session: UploadMediaData) => {
    setSelectedAssessmentType(session);
    handleSetAssementType(session);
  };

  const {
    isLoadingsendAssessmentEmailToCandidates,
    sendAssessmentEmailToCandidatesSuccessData,
  } = useAppSelector(sendAssessmentEmailToCandidatesSelector);

  function handleSuccessSendAssessmentInvitationToCandidate() {
    setCurrentlySelectedAssessmentToSendvalue(-1);
    setCandidateEmailList([]);
    setEmail('');
  }

  useEffect(() => {
    if (Object.keys(sendAssessmentEmailToCandidatesSuccessData).length !== 0) {
      let failedEmails = false;
      for (const email in sendAssessmentEmailToCandidatesSuccessData) {
        const status = sendAssessmentEmailToCandidatesSuccessData[email];
        if (status === 'Failure') {
          failedEmails = true;
          break;
        }
      }
      if (!failedEmails) handleSuccessSendAssessmentInvitationToCandidate();
    }
  }, [sendAssessmentEmailToCandidatesSuccessData]);

  const [openCopyLinkSettingsModal, setOpenCopyLinkSettingsModal] =
    useState(false);

  function handleopenCopyLinkSettingsModal() {
    setOpenCopyLinkSettingsModal(true);
  }
  function handleCloseCopyLinkSettingsModal() {
    setOpenCopyLinkSettingsModal(false);
  }

  async function generateSendCandidateAssessmentLinkToSend() {
    const arrayToEncrypt = {
      senderApiKey: sessionStorage.getItem('apiKey')
        ? sessionStorage.getItem('apiKey')
        : '',
      selectedAssessmentType: selectedAssessmentType,
    };

    const encryptedArray = btoa(JSON.stringify(arrayToEncrypt));
    const link = `${window.location.origin}/#/candidateQuestionnaire?token=${encryptedArray}`;

    await navigator.clipboard
      .writeText(link)
      .then(() => {
        Toast(t('SendAssessments.LinkCopiedToClipboard'), 'success');
      })
      .catch(() => {
        Toast(t('SendAssessments.FailedToCopyLink'), 'error');
      });
  }
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setQuestionnaireSearchValueSearchValue(searchValue);

    const filtered = SessionTypes.filter((session) =>
      session.name.toLowerCase().includes(searchValue)
    );

    setFilteredSessions(filtered);
  };

  return (
    <AuthWrapper>
      {isLoadingSessionTypes || loading ? (
        <Loader />
      ) : (
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'left',
            p: 3,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              width: '100%',
              height: 'auto',
              mb: 1,
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
              gap: 3,
            }}
          >
            <Box
              sx={{
                width: 'auto',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: ' var(--body-text, #33344B)',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '24px',
                  letterSpacing: '-0.176p',
                }}
                label='Send Questionnaire'
              />
              <Box
                sx={{
                  border: '1px solid #DFDFDF',
                  color: '#BDBDBD',
                  width: '40%',
                  marginLeft: isMobile ? 1 : isTablet ? 2 : 2,
                  height: '40px',
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                  borderRadius: '35px',
                  padding: 2,
                  backgroundColor: '#FAFAFA',
                }}
              >
                <GlobalSearchIcon />
                <InputBase
                  id='searchBar'
                  sx={{
                    color: '#676767',
                    fontFamily: 'Poppins',
                    width: '100%',
                    padding: 0.5,
                  }}
                  placeholder='Search for Questionnaires'
                  inputProps={{ 'aria-label': 'Search for Questionnaires' }}
                  onChange={handleSearchChange}
                  value={questionnaireSearchValue}
                />
              </Box>
            </Box>
          </Paper>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'space-evenly',
              alignItems: 'center',
              p: 2,
              width: '100%',
              flexDirection: isMobile || isTablet ? 'column' : 'row',
              border: '1px solid #D4D4D4',
              borderRadius: '12px',
              background: '#F9F9F9',
            }}
            id='AssessmentBox'
          >
            <Box
              sx={{
                display: 'block',
                width: '100%',
                maxWidth: isMobile || isTablet ? '100%' : '50%',
                gap: 2,
                p: 2,
                maxHeight: '55vh',
                minHeight: '55vh',
                overflow: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                border: '1px solid #CFCFCF',
                backgroundColor: '#FFF',
                mt: 1,
                mb: 1,
              }}
            >
              <Typography
                label='Select Questionnaire'
                sx={{
                  color: '#1206f9',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              />

              {filteredSessions.map(
                (session: UploadMediaData, index: number) => (
                  <Box
                    key={index}
                    onClick={() => handleBoxClick(session)}
                    sx={{
                      width: '100%',
                      height: 'fit-content',
                      backgroundColor:
                        selectedAssessmentType.id === session.id
                          ? '#F9F9F9'
                          : '#FFF',
                      border:
                        selectedAssessmentType.id === session.id
                          ? '1px solid #1206F9'
                          : '0.7px solid #CFCFCF',
                      borderRadius: '6px',
                      p: 2,
                      alignContent: 'center',
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      label={session.name}
                      sx={{
                        color: '#1D1B20',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        letterSpacing: '0.25px',
                      }}
                    />
                  </Box>
                )
              )}
            </Box>
            <Box
              sx={{
                display: 'block',
                width: '100%',
                maxWidth: isMobile || isTablet ? '100%' : '50%',
                gap: 2,
                p: 2,
                maxHeight: '55vh',
                minHeight: '55vh',
                overflow: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                border: '1px solid #D4D4D4',
                backgroundColor: '#FFF',
                mt: 1,
                mb: 1,
              }}
            >
              <Typography
                label='Prompts'
                sx={{
                  color: '#1206f9',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              />
              {practiseSections.length > 0 ? (
                <PromptsAccordation practiseSections={practiseSections} />
              ) : (
                <Typography
                  label={t(
                    'SendAssessments.CurrentlyPromptsAreNotAvailalbeForSelectedAssessmentType'
                  )}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: '12px',
              border: '1px solid #D4D4D4',
              p: 2,
              mt: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,

                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddEmailComponent
                handleAddCandidate={handleAddCandidate}
                email={email}
                setEmail={setEmail}
                candidateEmailList={candidateEmailList}
                setCandidateEmailList={setCandidateEmailList}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                mt: 2,
                p: 3,
                display: 'flex',
                alignContent: 'flex-start',
                justifyContent: 'flex-end',
                gap: 3,
              }}
            >
              <Button
                id='copyCandidateAssessmentLinkButton'
                variant='contained'
                disabled={selectedAssessmentType.id === -1}
                onClick={handleopenCopyLinkSettingsModal}
                sx={{
                  borderRadius: '1rem',
                  background: '#FFF',
                  pl: 4,
                  pr: 4,
                  width: 'fit-content',
                  height: 'fit-content',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                  color: '#1206F9',
                  border: '1px solid #1206f9',
                  '&:hover': {
                    background: '#FFF',
                    color: '#1206F9',
                    border: '1px solid #1206f9',
                    boxShadow: 'none',
                  },
                }}
                label={t('SendAssessments.CopyLink')}
                startIcon={<CopyLinkIcon />}
              />
              <Button
                id='inviteCandidateButton'
                variant='contained'
                disabled={candidateEmailList.length === 0}
                onClick={handleSendAssessmentInvitationToCandidate}
                sx={{
                  borderRadius: '1rem',
                  background: '#1206F9',
                  pl: 4,
                  pr: 4,
                  width: 'fit-content',
                  height: 'fit-content',

                  fontSize: '0.75rem',
                  fontWeight: 500,

                  color: '#fff',
                  '&:hover': {
                    background: '#1206F9',
                    color: '#FFF',
                    border: '1px solid #1206f9',
                    boxShadow: 'none',
                  },
                }}
                label='Send'
              />
            </Box>
          </Box>
          <CopyLinkSettingsModal
            openCopyLinkSettingsModal={openCopyLinkSettingsModal}
            handleCloseCopyLinkSettingsModal={handleCloseCopyLinkSettingsModal}
            selectedAssessmentType={selectedAssessmentType}
            generateSendCandidateAssessmentLinkToSend={
              generateSendCandidateAssessmentLinkToSend
            }
          />
        </Paper>
      )}

      {isLoadingsendAssessmentEmailToCandidates && <Loader />}
    </AuthWrapper>
  );
}

export default SendCandidateAssessments;
