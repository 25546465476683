import React from 'react';

interface QuestionnaireVideoIconProps {
  isSelected?: boolean;
}

const QuestionnaireVideoIcon: React.FC<QuestionnaireVideoIconProps> = ({
  isSelected = false,
}) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.23883 20.4292C3.69754 20.4292 3.23006 20.2405 2.8364 19.8633C2.45914 19.4696 2.27051 19.0021 2.27051 18.4609V6.65094C2.27051 6.10965 2.45914 5.65037 2.8364 5.27311C3.23006 4.87945 3.69754 4.68262 4.23883 4.68262H16.0487C16.59 4.68262 17.0493 4.87945 17.4266 5.27311C17.8202 5.65037 18.0171 6.10965 18.0171 6.65094V11.0797L21.9537 7.14302V17.9688L18.0171 14.0321V18.4609C18.0171 19.0021 17.8202 19.4696 17.4266 19.8633C17.0493 20.2405 16.59 20.4292 16.0487 20.4292H4.23883ZM4.23883 18.4609H16.0487V6.65094H4.23883V18.4609ZM4.23883 18.4609V6.65094V18.4609Z'
        fill={isSelected ? '#FFFFFF' : '#49454F'}
      />
    </svg>
  );
};

export default QuestionnaireVideoIcon;
