/** @format */

import React, { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './dashboard.css';
import ProcessDataListTable from './ProcessDataListTable';
import { Box } from '@mui/material';
import Loader from 'widgets/Loader/Loader';
import useDashboard from './hooks/useDashboard';
import { useAppDispatch } from 'redux/store';
import { GetUserRolesService } from 'services/CommonServices/UserRoles-Serice';
import { FilteredProcessDataList } from 'types/Dashboard-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import { ProtectedUrls } from 'routes/urls';
import Typography from 'widgets/Typography/Typography';
import { decryptApiResponse } from 'shared/AesDecryption/AesDecryption';
interface Params {
  [key: string]: string;
}

function IFrameDashboardComponent() {
  const [filteredDataWithInputs, setFilteredDataWithInputs] = useState<
    FilteredProcessDataList[]
  >([]);
  const [apiKey, setApiKey] = useState('');
  const [env, setEnv] = useState('');
  const [loading, setLoading] = useState(false);
  const { processDataListWithSession, setProcessDataListWithSession } =
    useDashboard(filteredDataWithInputs);

  const [processDataListVersion2, setProcessDataListVersion2] = useState<
    ProcessDataListVersion2[]
  >([]);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    setLoading(true);
    const {
      apikey: newApiKey,

      env: envName,
    } = params;

    // Check if the values exist before updating the state

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (envName) {
      setEnv(envName);
    }

    return () => { };
  }, [params]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
          }`;
      } else if (env === 'ci') {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
          }`;
      } else if (env === 'qa') {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
          }`;
      } else if (env === 'ua') {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
          }`;
      } else {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
          }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          // const APIResponse = decryptApiResponse(response.data);
          const APIResponse = response.data;

          setProcessDataListVersion2(APIResponse);
          setLoading(false);
        })
        .catch((error) => { });
    }
  }, [apiKey, env]);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Box>
      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
          marginTop: 2,
          marginLeft: 2,
        }}
        label='dashboard.Dashboard'
      />

      <ProcessDataListTable
        processDataListWithSession={processDataListWithSession}
        setProcessDataListWithSession={setProcessDataListWithSession}
        onFilterDataChange={setFilteredDataWithInputs}
        processDataListVersion2={processDataListVersion2}
      />
    </Box>
  );
}

export default IFrameDashboardComponent;
