import { Box, IconButton, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';

import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { TabsForPromptSectionProps } from 'types/VideoPreview-types';
import Loader from 'widgets/Loader/Loader';
import { useAppDispatch } from 'redux/store';
import Button from 'widgets/CustomButton/Button';
import EditSummaryIcon from 'icons/EditSummaryIcon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Toast } from 'widgets/Toast/Toast';
import { usePDF } from 'react-to-pdf';
import MedicalSummary from './MedicalSummary';
import {
  GetProcessedDataListForParticipantsVersion2ServiceAPI,
  updateSummaryIframeService,
  updateSummaryService,
} from 'services/VideoPreview-services';

import CasualSummary from './CasualSummary';
import InterviewSummary from './InterviewSummary';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function TabsForPromptSection({
  selectedSummaryTab,
  setSummaryTab,
  ProcessDataListForParticipantsVersion2,
  selectedPrompt,
  sessionId,
  isIframe,
  apiKey = '',
}: TabsForPromptSectionProps) {
  const dispatch = useAppDispatch();
  const { toPDF, targetRef } = usePDF({ filename: 'Report.pdf' });
  const [isEditing, setIsEditing] = useState(false);
  const CasualSummaryArray = [1, 2, 3, 4, 5, 6, 8, 9, 11, 12, 13, 57];
  // const InterviewSummaryArray = [
  //   7, 10, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  //   31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
  //   50, 51, 52, 53, 54, 55,
  // ];

  const firstParticipant = ProcessDataListForParticipantsVersion2?.[0];
  const detailedSummaryLoad = firstParticipant?.detailed_summary;

  const isDetailedSummaryEmpty =
    detailedSummaryLoad && Object.keys(detailedSummaryLoad).length === 0;

  const [editedContent, setEditedContent] = useState<Record<string, string>>(
    {}
  );

  const [showOriginal, setShowOriginal] = useState(true);

  const detailedSummaryEdited =
    ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary_edited;

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const handleExport = async () => {
    const Payload = {
      log: `Summary Exported to pdf`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));
    await toPDF();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (isIframe) {
      dispatch(updateSummaryIframeService(sessionId, editedContent, apiKey));
    } else {
      const Payload = {
        log: `Summary Updated for Session ${sessionId}`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));
      dispatch(updateSummaryService(sessionId, editedContent));
      dispatch(
        GetProcessedDataListForParticipantsVersion2ServiceAPI(sessionId)
      );
    }

    setIsEditing(false);
  };
  const handleCancle = () => {
    setIsEditing(false);
  };

  const handleCopy = () => {
    if (targetRef.current) {
      const textToCopy = targetRef.current.innerText.trim();

      if (textToCopy) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            Toast('Content copied successfully!', 'success');
          })
          .catch(() => {
            Toast('Failed to copy', 'error');
          });
      } else {
        Toast('No content to copy', 'error');
      }
    }
  };

  if (!ProcessDataListForParticipantsVersion2) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: 'block', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          minHeight: '10vh',
          maxWidth: isMobile || isTablet ? '100%' : '85vw',
          backgroundColor: '#F9F9FB',
          boxShadow: '0px 4px 50px 0px #65646414',
          justifyContent: 'flex-end',
          gap: 3,
          pr: 5,
        }}
      >
        <Button
          variant='outlined'
          label={isEditing ? 'Cancel' : 'Edit'}
          sx={{
            width: 'auto',
            height: 'auto',
            p: 1,
            color: '#1206F9',
            borderColor: '#1206F9',
            fontSize: '16px',
            textTransform: 'none',
            minWidth: '80px',
          }}
          disabled={isDetailedSummaryEmpty}
          startIcon={!isEditing && <EditSummaryIcon />}
          onClick={isEditing ? handleCancle : handleEditClick}
        />
        <Button
          variant='contained'
          label={isEditing ? 'Save' : 'Export'}
          sx={{
            width: 'auto',
            height: 'auto',
            p: 1,
            backgroundColor: '#0904F0',
            color: '#FFF',
            fontSize: '16px',
            textTransform: 'none',
            minWidth: '80px',
          }}
          disabled={isDetailedSummaryEmpty}
          onClick={isEditing ? handleSaveClick : handleExport}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile || isTablet ? 'column' : 'row',
        }}
      >
        {detailedSummaryEdited &&
          Object.values(detailedSummaryEdited).some(
            (value) =>
              value &&
              (typeof value !== 'object' ||
                Object.values(value).some((nestedValue) => nestedValue))
          ) && (
            <Box
              sx={{
                display: 'block',
                width: '100%',

                maxWidth: isMobile || isTablet ? '100%' : '20vw',
                height: '100% !important',
                p: 3,
                gap: 2,
                textAlign: 'left',
              }}
            >
              {detailedSummaryEdited &&
                Object.values(detailedSummaryEdited).some(
                  (value) =>
                    value &&
                    (typeof value !== 'object' ||
                      Object.values(value).some((nestedValue) => nestedValue))
                ) && (
                  <>
                    <Button
                      variant='text'
                      onClick={() => setShowOriginal(true)}
                      label='Mirro AI Original Report'
                      sx={{
                        color: showOriginal ? '#1906F9' : '#444655',
                        textTransform: 'none',
                        width: 'auto',
                        minWidth: '15vw',
                        textAlign: 'left',
                      }}
                    />

                    <Button
                      variant='text'
                      onClick={() => setShowOriginal(false)}
                      disabled={!detailedSummaryEdited}
                      label='Edited'
                      sx={{
                        color: !showOriginal ? '#1906F9' : '#444655',
                        textTransform: 'none',
                        minWidth: '15vw',
                        textAlign: 'left',
                      }}
                    />
                  </>
                )}
            </Box>
          )}
        <Box
          sx={{
            width: '100%',
            height: 'auto',

            p:
              detailedSummaryEdited &&
              Object.values(detailedSummaryEdited).some(
                (value) =>
                  value &&
                  (typeof value !== 'object' ||
                    Object.values(value).some((nestedValue) => nestedValue))
              )
                ? 2
                : 10,
            maxWidth:
              isMobile || isTablet
                ? '100%'
                : detailedSummaryEdited &&
                  Object.values(detailedSummaryEdited).some(
                    (value) =>
                      value &&
                      (typeof value !== 'object' ||
                        Object.values(value).some((nestedValue) => nestedValue))
                  )
                ? '60vw'
                : '75vw',
          }}
        >
          <Box
            ref={targetRef}
            sx={{
              padding: '20px',
              maxWidth: '99%',
              gap: 2,
              background: 'transparent',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',

              textOverflow: 'initial',
              mb: 2,
            }}
          >
            {ProcessDataListForParticipantsVersion2?.[0]?.session_type_id ===
              58 ||
            ProcessDataListForParticipantsVersion2?.[0]?.session_type_id ===
              56 ||
            ProcessDataListForParticipantsVersion2?.[0]?.session_type_id ===
              59 ? (
              <MedicalSummary
                ProcessDataListForParticipantsVersion2={
                  ProcessDataListForParticipantsVersion2
                }
                isEditing={isEditing}
                showOriginal={showOriginal}
                editedContent={editedContent}
                setEditedContent={setEditedContent}
              />
            ) : (
              <>
                {CasualSummaryArray.includes(
                  ProcessDataListForParticipantsVersion2?.[0]?.session_type_id
                ) ? (
                  <CasualSummary
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                    isEditing={isEditing}
                    showOriginal={showOriginal}
                    editedContent={editedContent}
                    setEditedContent={setEditedContent}
                  />
                ) : (
                  <InterviewSummary
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                    isEditing={isEditing}
                    showOriginal={showOriginal}
                    editedContent={editedContent}
                    setEditedContent={setEditedContent}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: 'auto',
          }}
        >
          <IconButton
            onClick={handleCopy}
            sx={{
              color: 'inherit',
              width: 'fit-content',
              height: 'fit-content',
              top: 40,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disabled={isDetailedSummaryEmpty}
          >
            <ContentCopyIcon />
            <Typography label='Copy' sx={{ cursor: 'pointer' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default TabsForPromptSection;
