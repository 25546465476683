import i18n from 'i18next';
import {
  addAuditLogsFailure,
  addAuditLogsLoad,
  addAuditLogsSuccess,
} from 'redux/slices/AccountSetting-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { AuditLogPayload } from 'types/common/Common-types';

export const AddAuditLogService =
  (Payload: AuditLogPayload): AppThunk =>
  async (dispatch) => {
    dispatch(addAuditLogsLoad());

    await commonAPI
      .post(ProtectedUrls.addAuditLogs.url(Payload))
      .then((response) => {
        dispatch(addAuditLogsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          addAuditLogsFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };
