/** @format */

import React, { useCallback, useEffect, useState } from 'react';

import Typography from 'widgets/Typography/Typography';
import Divider from '@mui/material/Divider';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  GetReprocessVersion2VideoService,
  deleteProcessVideoV2Service,
  getProcessDataListForCandidatesServiceApi,
  getProcessDataListVersion2Service,
} from 'services/Gallery-service';
import {
  getSessionTypesSelector,
  globalSearchSelector,
  getProcessDataListVersion2Selector,
  getEmotionTypeSelector,
  deleteProcessVideoV2Selector,
  getReprocessVersion2VideoSelector,
  getAIPermissionsSelector,
} from 'redux/selectors/selectors';
import usePagination from 'hooks/usePagination';
import Pagination from '@mui/material/Pagination';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import {
  GetReprocessVideoServiceProps,
  ProcessDataListVersion2,
} from 'types/Gallery.types';
import { useHistory } from 'react-router-dom';
import DeleteModal from 'widgets/DeleteModal/DeleteModal';
import NestedModal from '../ReprocessNestedModal';
import { ProtectedRoutes } from 'routes/routes';
import VideoDisplayCard from 'widgets/VideoDisplayCard/VideoDisplayCard';
import { GetEmotionTypeListService } from 'services/CommonServices/Emotion-service';
import Loader from 'widgets/Loader/Loader';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import Button from 'widgets/CustomButton/Button';
import { Box } from '@mui/material';
import { Toast } from 'widgets/Toast/Toast';
import { t } from 'i18next';
import { getAiPermissionServiceApi } from 'services/AiPermission-service';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function GalleryVersion2() {
  const perPage = 8;

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false); //

  const [coaching_session_reports_id, set_coaching_session_reports_id] =
    useState<number>(-1);
  const history = useHistory();
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const dispatch = useAppDispatch();

  const { isLoadingProcessDataListVersion2, processDataListVersion2 } =
    useAppSelector(getProcessDataListVersion2Selector);
  const [processDataListWithSession, setProcessDataListWithSession] = useState<
    ProcessDataListVersion2[]
  >([]);

  const [
    filteredProcessDataListWithSession,
    setFilteredProcessDataListWithSession,
  ] = useState<ProcessDataListVersion2[]>(processDataListWithSession);

  const { SessionTypes } = useAppSelector(getSessionTypesSelector);
  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);

  const videoAnalysisEnabled = false;
  // selectedSessionType.id === 59 ? getAIPermissions.video_analysis :
  const textAnalysisEnabled = getAIPermissions.text_analysis;

  useEffect(() => {
    const updatedProcessDataList = processDataListVersion2.map((item) => {
      const sessionInfo = SessionTypes.find(
        (session) => session.id === item.session_type_id
      );
      return {
        ...item,
        sessionName: sessionInfo ? sessionInfo.name : '',
      };
    });
    setProcessDataListWithSession(updatedProcessDataList);
  }, [processDataListVersion2, SessionTypes]);

  useEffect(() => {
    dispatch(getProcessDataListVersion2Service());
    dispatch(getSessionTypesServiceApi(-1));
    dispatch(getAiPermissionServiceApi());
  }, [dispatch]);

  useEffect(() => {
    setFilteredProcessDataListWithSession(processDataListWithSession);
  }, [processDataListWithSession]);
  const { currentData, handleChange, page, count, setCurrentPage } =
    usePagination(filteredProcessDataListWithSession, perPage);

  const { isDeletingProcessVideo } = useAppSelector(
    deleteProcessVideoV2Selector
  );
  const handleDeleteProcessVideo = () => {
    const Payload = {
      log: `Session ${coaching_session_reports_id} deleted`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));
    dispatch(
      deleteProcessVideoV2Service(
        coaching_session_reports_id,
        handleOpenOnDeleteSuccessModal
      )
    );
    handleCloseDeleteModal();
  };

  const [reprocessVideoData, setReprocessVideoData] =
    useState<GetReprocessVideoServiceProps>({
      video_title: '',
      metrics_processing_events_history_id_list: -1,
      sessionDetails: {
        id: -1,
        name: '',
      },
      text_analysis: true,
      video_analysis: true,
    });

  useEffect(() => {
    if (textAnalysisEnabled) {
      setReprocessVideoData({
        video_title: '',
        metrics_processing_events_history_id_list: -1,
        sessionDetails: {
          id: -1,
          name: '',
        },
        text_analysis: textAnalysisEnabled,
        video_analysis: videoAnalysisEnabled,
      });
    }
  }, [textAnalysisEnabled, videoAnalysisEnabled]);

  const [openReprocessVideoModal, setReprocessVideoModal] =
    useState<boolean>(false);
  const [onDeleteSuccessModal, setOnDeleteSuccessModal] = useState(false);

  function handleOpenOnDeleteSuccessModal() {
    setOnDeleteSuccessModal(true);
  }
  function handleCloseOnDeleteSuccessModal() {
    setOnDeleteSuccessModal(false);
  }

  const handleOpenReprocessVideoModal = () => setReprocessVideoModal(true);

  const [
    openCancelledReprocessVideoModal,
    setOpenCancelledReprocessVideoModal,
  ] = useState(false);
  function handleOpenCancelledReprocessVideoModal() {
    setReprocessVideoModal(false);
    setOpenCancelledReprocessVideoModal(true);
    handleCloseReprocessChildVideoModal();
  }
  function handleCloseCancelledReprocessVideoModal() {
    setOpenCancelledReprocessVideoModal(false);
  }

  const [openSuccessReprocessVideoModal, setOpenSuccessReprocessVideoModal] =
    useState(false);
  function handleOpenSuccessReprocessVideoModal() {
    setOpenSuccessReprocessVideoModal(true);
  }
  function handleCloseSuccessReprocessVideoModal() {
    setOpenSuccessReprocessVideoModal(false);
  }

  const handleCloseReprocessVideoModal = () =>
    handleOpenCancelledReprocessVideoModal();
  const [openReprocessChildVideoModal, setReprocessChildVideoModal] =
    React.useState(false);

  const handleOpenReprocessChildVideoModal = () =>
    setReprocessChildVideoModal(true);

  const handleCloseReprocessChildVideoModal = () => {
    setReprocessChildVideoModal(false);
    setReprocessVideoModal(false);
  };

  const onReprocessConfirmation = () => {
    if (reprocessVideoData.sessionDetails.id === -1) {
      Toast(t('MyAssessments.SessionTypeIsRequired'), 'error');
    } else if (reprocessVideoData.video_title === '') {
      Toast(t('MyAssessments.VideoDescriptionIsRequired'), 'error');
    } else {
      const Payload = {
        log: `session ${coaching_session_reports_id} reprocess initiated`,
        log_type: 'info',
      };
      dispatch(AddAuditLogService(Payload));
      dispatch(
        GetReprocessVersion2VideoService(
          reprocessVideoData,
          handleOpenSuccessReprocessVideoModal
        )
      );
      handleCloseReprocessChildVideoModal();
    }
  };
  const { isLoadingReprocessVersion2Video } = useAppSelector(
    getReprocessVersion2VideoSelector
  );

  const onClickVideoCard = (
    coaching_session_reports_id: number,
    totalAttendees: number
  ) => {
    history.push({
      pathname: ProtectedRoutes.videoPreviewAdvance,
      state: {
        coaching_session_reports_id: coaching_session_reports_id,
      },
    });

    localStorage.setItem(
      'coaching_session_reports_id',
      coaching_session_reports_id.toString()
    );
  };

  function searchVideosByTitle(searchQuery: string) {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const searchedData = processDataListWithSession.filter(
      ({ session_title }) => {
        if (session_title)
          return session_title.toLowerCase().includes(lowerCaseSearchQuery);

        return {};
      }
    );

    setFilteredProcessDataListWithSession(searchedData);
  }
  const { globalSearchValue } = useAppSelector(globalSearchSelector);
  useEffect(() => {
    searchVideosByTitle(globalSearchValue);
  }, [globalSearchValue]);

  interface SessionInfo {
    coaching_session_reports_id: number;
    processing_event_name: string;
    format: string;
    session_title: string;
    session_type_id: number;
    total_attendees: number;
    version_number: string;
    process_status: number;
    overall_emotion: number;
    processing_start_date_time: string;
    processing_end_date_time: string;
    overall_energy: number;
    engagement_score: number;
    positivity_score: number;
    variation_score: number;
    thumbnail_image_bytes: string;
    session_name: string;
    error_flag: boolean;
    overall_summary: string;
    overall_emotion_name: string;
  }
  const { isLoadingEmotionType, errorEmotionType, EmotionTypeList } =
    useAppSelector(getEmotionTypeSelector);

  useEffect(() => {
    dispatch(GetEmotionTypeListService());
  }, [dispatch]);

  const getEmotionName = useCallback(
    (emotionId: number) => {
      const matchedEmotion = EmotionTypeList.find(
        (emotion) => emotion.id === emotionId
      );
      return matchedEmotion?.name || '';
    },
    [EmotionTypeList]
  );
  const [toggleProcessDataListApiCall, setToggleProcessDataListApiCall] =
    useState(false);

  useEffect(() => {
    if (toggleProcessDataListApiCall) {
      dispatch(getProcessDataListForCandidatesServiceApi());
    } else {
      dispatch(getProcessDataListVersion2Service());
    }
  }, [toggleProcessDataListApiCall]);
  if (!processDataListVersion2) {
    return <Loader />;
  }

  return (
    <div>
      {isLoadingProcessDataListVersion2 ||
      isLoadingReprocessVersion2Video ||
      isLoadingEmotionType ||
      isDeletingProcessVideo ? (
        <Loader />
      ) : (
        <div>
          <div style={{ minHeight: '80vh' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
              }}
            >
              <Typography
                sx={{
                  color: '#2C2C2C',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '-0.198px',
                  marginTop: 2,
                  marginLeft: 2,
                }}
                label='gallery.Gallery'
              />
              <Button
                variant='outlined'
                sx={{
                  borderRadius: '25px',
                  background: '#fff',
                  borderColor: '#1206f9',
                  float: 'right',
                  width: 'fit-content',
                  height: 'fit-content',
                  mt: 2,
                  fontWeight: 600,
                  color: '#1206f9',
                }}
                id='basic-button-toggleProcessDataListApiCall'
                onClick={() => {
                  setToggleProcessDataListApiCall(
                    !toggleProcessDataListApiCall
                  );
                  localStorage.setItem('currentPageGalleryPagination', '1');
                  setCurrentPage(1);
                }}
                label={
                  toggleProcessDataListApiCall
                    ? 'View sessions'
                    : 'View candidate assessments'
                }
              />
            </Box>

            <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
            {currentData().length === 0 ? (
              <Typography
                sx={{
                  height: '60vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  color: '#2C2C2C',
                  fontFamily: 'Poppins',
                }}
                variant='h4'
                label='gallery.NoVideosToDisplay'
              />
            ) : (
              <div className='gallery-filter-component-video-list'>
                {currentData().map((videoData: SessionInfo, index: number) => {
                  return (
                    <VideoDisplayCard
                      key={index}
                      title={
                        videoData.session_title === null || undefined
                          ? videoData.processing_event_name
                          : videoData.session_title
                      }
                      engagementScore={videoData.engagement_score}
                      endTime={videoData.processing_end_date_time}
                      startTime={videoData.processing_start_date_time}
                      overallEmotion={videoData.overall_emotion_name}
                      thumbnailImageBytes={videoData.thumbnail_image_bytes}
                      sessionName={videoData.session_name}
                      metrics_processing_event_history_id={
                        videoData.coaching_session_reports_id
                      }
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      set_metrics_processing_event_history_id={
                        set_coaching_session_reports_id
                      }
                      handleOpenReprocessVideoModal={
                        handleOpenReprocessVideoModal
                      }
                      setReprocessVideoData={setReprocessVideoData}
                      sessionDetails={{
                        id: videoData.session_type_id,
                        name: videoData.session_name,
                      }}
                      onClickVideoCard={onClickVideoCard}
                      totalAttendees={videoData.total_attendees}
                      error_flag={videoData.error_flag}
                      overall_summary={videoData.overall_summary}
                      process_status={videoData.process_status}
                      variation_score={videoData.variation_score}
                      engagement_score={videoData.engagement_score}
                      positivity_score={videoData.positivity_score}
                      overall_energy={videoData.overall_energy}
                      textAnalysisEnabled={textAnalysisEnabled}
                      videoAnalysisEnabled={videoAnalysisEnabled}
                    />
                  );
                })}
              </div>
            )}
            <div
              style={{
                justifyContent: 'center',

                display: 'flex',
                margin: '20px',
              }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={count}
                size='large'
                page={page}
                onChange={handleChange}
                style={{ fontFamily: 'Poppins' }}
              />
            </div>
          </div>

          <DeleteModal
            openDeleteModal={openDeleteModal}
            onCloseDeleteModal={handleCloseDeleteModal}
            onConfirmDelete={handleDeleteProcessVideo}
            onCancelDelete={handleCloseDeleteModal}
          />
          <ConfirmationModal
            message='gallery.VideoDeletedSuccessfully'
            openConfirmationModal={onDeleteSuccessModal}
            handleCloseConfirmationModal={handleCloseOnDeleteSuccessModal}
          />
          <NestedModal
            openReprocessVideoModal={openReprocessVideoModal}
            handleCloseReprocessVideoModal={handleCloseReprocessVideoModal}
            onReprocessConfirmation={onReprocessConfirmation}
            setReprocessVideoData={setReprocessVideoData}
            reprocessVideoData={reprocessVideoData}
            handleOpenReprocessChildVideoModal={
              handleOpenReprocessChildVideoModal
            }
            openReprocessChildVideoModal={openReprocessChildVideoModal}
            handleCloseReprocessChildVideoModal={
              handleOpenCancelledReprocessVideoModal
            }
            isBulkReprocess={false}
          />
          <ConfirmationModal
            message={'gallery.ReprocessCancelled'}
            openConfirmationModal={openCancelledReprocessVideoModal}
            handleCloseConfirmationModal={
              handleCloseCancelledReprocessVideoModal
            }
          />
          <ConfirmationModal
            message={'gallery.FileIsBeingReprocessedCheckBackAfterSometime'}
            openConfirmationModal={openSuccessReprocessVideoModal}
            handleCloseConfirmationModal={handleCloseSuccessReprocessVideoModal}
          />
        </div>
      )}
    </div>
  );
}

export default GalleryVersion2;
