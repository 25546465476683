import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import { CoachingSessionReport, SectionConfig } from 'types/VideoPreview-types';
import Typography from 'widgets/Typography/Typography';
import {
  Exception_error_Summary,
  Processing_Status,
  Processing_is_in_progress,
  Summary_Status,
} from 'constants/videoPreview';
import { Toast } from 'widgets/Toast/Toast';
import { TextField } from '@mui/material';

type KeyFindingsKeys =
  | 'strengths_demonstrated'
  | 'development_areas'
  | 'best_practices_observed'
  | 'recommendations';

type attention_patternsKeys =
  | 'high_engagement_periods'
  | 'low_engagement_periods'
  | 'engagement_triggers';

const attention_patternsKeys: Record<string, attention_patternsKeys> = {
  'High Engagement Periods': 'high_engagement_periods',
  'Low Engagement Periods': 'low_engagement_periods',
  'Engagement Triggers': 'engagement_triggers',
};

const keyFindingsKeys: Record<string, KeyFindingsKeys> = {
  'Strengths Demonstrated': 'strengths_demonstrated',
  'Development Areas': 'development_areas',
  'Best Practices Observed': 'best_practices_observed',
  Recommendations: 'recommendations',
};

function CasualSummary({
  ProcessDataListForParticipantsVersion2,
  isEditing,
  showOriginal,
  editedContent,
  setEditedContent,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  isEditing: boolean;
  showOriginal: boolean;
  editedContent: Record<string, string>;
  setEditedContent: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}) {
  const firstParticipant = ProcessDataListForParticipantsVersion2?.[0];
  const detailedSummaryLoad = firstParticipant?.detailed_summary;

  const isDetailedSummaryEmpty =
    detailedSummaryLoad && Object.keys(detailedSummaryLoad).length === 0;

  const sectionsConfig: SectionConfig[] = useMemo(() => {
    if (isDetailedSummaryEmpty && firstParticipant.process_status !== 13) {
      return [
        {
          label: `${Processing_Status}`,
          rows: 1,
          value: `${Processing_is_in_progress}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    if (isDetailedSummaryEmpty && firstParticipant.process_status === 13) {
      return [
        {
          label: `${Summary_Status}`,
          rows: 1,
          value: `${Exception_error_Summary}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    return showOriginal
      ? [
          {
            label: 'Iteraction Summary',
            rows: 5,
            value:
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.interaction_summary
                  ?.communication_style || ''
              }\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.interaction_summary?.engagement_level ||
                ''
              }`,
            onChangeKey: 'interaction_summary',
          },

          {
            label: 'Communication Patterns',
            rows: 18,
            value:
              `Strengths:\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary?.general_analysis?.communication_patterns?.strengths?.join(
                  '\n'
                ) || ''
              }\n\n` +
              `Areas for Improvement:\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary?.general_analysis?.communication_patterns?.areas_for_improvement?.join(
                  '\n'
                ) || ''
              }\n\n` +
              `Interaction Dynamics:\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.communication_patterns
                  ?.interaction_dynamics || ''
              }\n\n` +
              `Nonverbal Indicators:\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.communication_patterns
                  ?.nonverbal_indicators || ''
              }\n`,
            onChangeKey: 'communication_patterns',
          },

          {
            label: 'Attention Patterns',
            rows: 12,
            value: Object.keys(attention_patternsKeys)
              .map((sectionTitle) => {
                const key = attention_patternsKeys[sectionTitle];
                const items =
                  ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                    ?.general_analysis?.engagement_analysis
                    ?.attention_patterns?.[key] || [];

                if (Array.isArray(items)) {
                  return `${sectionTitle}:\n${items.join('\n')}\n`;
                } else {
                  return '';
                }
              })
              .join('\n'),
            onChangeKey: 'attention_patterns',
          },

          {
            label: 'Effectiveness Metrics',
            rows: 4,
            value:
              `Clarity Score: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.effectiveness_metrics?.clarity_score || 0
              }\n` +
              `Engagement Score: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.effectiveness_metrics?.engagement_score ||
                0
              }\n` +
              `Interaction Score: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.effectiveness_metrics
                  ?.interaction_score || 0
              }\n` +
              `Energy Level: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.general_analysis?.effectiveness_metrics?.energy_level || 0
              }`,
            onChangeKey: 'effectiveness_metrics',
          },

          {
            label: 'Key Findings',
            rows: 15,
            value: Object.keys(keyFindingsKeys)
              .map((sectionTitle) => {
                const key = keyFindingsKeys[sectionTitle];
                const items =
                  ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                    ?.general_analysis?.key_findings?.[key] || [];

                if (Array.isArray(items)) {
                  return `${sectionTitle}:\n${items.join('\n')}\n`;
                } else {
                  return '';
                }
              })
              .join('\n'),
            onChangeKey: 'key_findings',
          },
        ]
      : [
          {
            label: 'Interaction summary',
            rows: 5,
            value: `${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.interaction_summary || ''
            }\n`,
            onChangeKey: 'interaction_summary',
          },
          {
            label: 'Communication Patterns',
            rows: 18,
            value: ` ${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.communication_patterns || ''
            }\n`,
            onChangeKey: 'communication_patterns',
          },
          {
            label: 'Attention Patterns',
            rows: 12,
            value: ` ${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.attention_patterns || ''
            }\n`,
            onChangeKey: 'attention_patterns',
          },
          {
            label: 'Effectiveness Metrics',
            rows: 4,
            value: ` ${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.effectiveness_metrics || ''
            }\n`,
            onChangeKey: 'effectiveness_metrics',
          },
          {
            label: 'Key Findings',
            rows: 15,
            value: ` ${
              ProcessDataListForParticipantsVersion2?.[0]
                ?.detailed_summary_edited?.key_findings || ''
            }\n`,
            onChangeKey: 'key_findings',
          },
        ];
  }, [showOriginal, ProcessDataListForParticipantsVersion2]);

  useEffect(() => {
    setEditedContent(
      sectionsConfig.reduce((acc, section) => {
        acc[section.onChangeKey] = section.value;
        return acc;
      }, {} as Record<string, string>)
    );
  }, [sectionsConfig]);

  const handleTextFieldChange = (key: string, newValue: string) => {
    setEditedContent((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleCopy = () => {
    const allDataToCopy = Object.values(sectionsConfig)
      .map((section) => section.value)
      .join('\n\n');
    if (allDataToCopy) {
      navigator.clipboard.writeText(allDataToCopy);
      Toast('Content copied successfully!', 'success');
    } else {
      Toast('Failed to copy', 'error');
    }
  };
  return (
    <Box>
      {isEditing ? (
        <div>
          {sectionsConfig.map((section, index) => (
            <section key={index} style={{ marginTop: 2, marginBottom: 2 }}>
              <Typography label={section.label} sx={{ mt: 1, mb: 1 }} />
              <TextField
                multiline
                rows={section.rows}
                value={editedContent[section.onChangeKey]}
                onChange={(e) =>
                  handleTextFieldChange(section.onChangeKey, e.target.value)
                }
                fullWidth
                variant='outlined'
                sx={{ fontSize: '14px' }}
              />
            </section>
          ))}
        </div>
      ) : (
        <>
          {sectionsConfig.map((section, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '4px',
                p: 1,
                maxWidth: '100%',
              }}
            >
              <Typography label='' sx={{ fontWeight: 600, fontSize: '16px' }}>
                {section.label}
              </Typography>
              <Typography
                label=''
                sx={{
                  fontSize: '14px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  maxWidth: '100%',
                  display: 'block',
                }}
              >
                {section.value || 'N/A'}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}

export default CasualSummary;
