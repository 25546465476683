import React, { SetStateAction, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Paper, TextField } from '@mui/material';
import Typography from 'widgets/Typography/Typography';
import Button from 'widgets/CustomButton/Button';
import checkLinkStatus from 'utils/checkLinkExpiration';
import {
  CandidateDetailsType,
  MandatoryDataForCandidateAssessmentSubmissionType,
} from 'types/SendAssessments-types';
import { useAppDispatch } from 'redux/store';
import { candidateAssignmentSubmissionCheckServiceApi } from 'services/CandidateAssessment-service';
import { useTranslation } from 'react-i18next';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

interface CandidateDetailsFormProps {
  setCandidateDetails: React.Dispatch<
    React.SetStateAction<CandidateDetailsType>
  >;
  mandatoryDataForCandidateAssessmentSubmission: MandatoryDataForCandidateAssessmentSubmissionType;
}

const CandidateDetailsForm: React.FC<CandidateDetailsFormProps> = ({
  setCandidateDetails,
  mandatoryDataForCandidateAssessmentSubmission,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t('CandidateAssessments.FirstNameIsRequired')
    ),
    lastName: Yup.string().required(
      t('CandidateAssessments.LastNameIsRequired')
    ),
    email: Yup.string()
      .matches(emailRegex, t('CandidateAssessments.InvalidEmail'))
      .required(t('CandidateAssessments.EmailIsRequired')),
  });
  const CandidateDetailsFormFieldArray = [
    {
      name: 'firstName',
      label: t('CandidateAssessments.FirstName'),
      type: 'text',
      disabled: false,
    },
    {
      name: 'lastName',
      label: t('CandidateAssessments.LastName'),
      type: 'text',
      disabled: false,
    },

    {
      name: 'email',
      label: t('CandidateAssessments.Email'),
      type: 'email',
      disabled: mandatoryDataForCandidateAssessmentSubmission.user_mail_id
        ? true
        : false,
    },
  ];
  const handleSubmit = (values: FormValues) => {
    const CandidateDetailsPayload = {
      ...values,
      assessmentId:
        mandatoryDataForCandidateAssessmentSubmission.selectedAssessmentType.id,
    };

    const Payload = {
      log: `Candidate Details for Questionnaire id ${mandatoryDataForCandidateAssessmentSubmission.selectedAssessmentType.id} filled `,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));

    dispatch(
      candidateAssignmentSubmissionCheckServiceApi(
        CandidateDetailsPayload,
        setCandidateDetails
      )
    );
  };

  const [assessmentLinkStatusExpiration, setAssessmentLinkStatusExpiration] =
    useState<{
      expired: boolean;
    }>({ expired: false });

  useEffect(() => {
    if (
      mandatoryDataForCandidateAssessmentSubmission &&
      mandatoryDataForCandidateAssessmentSubmission.expirationDateTimeStampInUtc
    ) {
      const interval = setInterval(() => {
        const status = checkLinkStatus(
          mandatoryDataForCandidateAssessmentSubmission.expirationDateTimeStampInUtc
        );
        if (status) setAssessmentLinkStatusExpiration(status);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [mandatoryDataForCandidateAssessmentSubmission]);

  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: mandatoryDataForCandidateAssessmentSubmission.user_mail_id,
  };
  return (
    <Paper elevation={5} sx={{ p: 5 }}>
      <Typography
        gutterBottom
        sx={{
          color: '#33344B',
          fontFamily: 'Poppins',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '23px',
        }}
        label={t('CandidateAssessments.PleaseEnterTheFollowingDetails')}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          {CandidateDetailsFormFieldArray.map((formField) => {
            return (
              <Field
                disabled={formField.disabled}
                as={TextField}
                name={formField.name}
                label={formField.label}
                variant='outlined'
                type={formField.type}
                fullWidth
                margin='normal'
                helperText={
                  <ErrorMessage
                    name={formField.name}
                    component='div'
                    className='error-message'
                  />
                }
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            );
          })}

          <Button
            fullWidth
            sx={{
              backgroundColor: '#1206f9',
              mt: '4%',
              color: '#fff',
              padding: 2,
              borderRadius: '25px',
              '&:hover': {
                backgroundColor: '#1206f9',
              },
            }}
            id='btnSignin'
            variant='contained'
            type='submit'
            label={t('CandidateAssessments.Submit')}
          />
        </Form>
      </Formik>
    </Paper>
  );
};

export default CandidateDetailsForm;
