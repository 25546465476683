/** @format */

import React, { useEffect, useState } from 'react';

import { Box, Container, Divider, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  getAvailableSubscriptionPlansSelector,
  getSubscriptionSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ProtectedRoutes } from 'routes/routes';
import Loader from 'widgets/Loader/Loader';
import {
  getAvailableSubscriptionPlansServiceApi,
  getSubscriptionServiceApi,
} from 'services/Subscription-service';
import {
  SubscriptionDetails,
  currentSubscriptionType,
} from 'types/Account-types';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import { AddAuditLogService } from 'services/CommonServices/AuditLogs-services';

function CurrentPlan() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleSubscriptionClick = () => {
    const Payload = {
      log: `Subscription page accessed`,
      log_type: 'info',
    };
    dispatch(AddAuditLogService(Payload));

    history.push(ProtectedRoutes.subscriptionsRoute);
  };

  useEffect(() => {
    dispatch(getAvailableSubscriptionPlansServiceApi());
    dispatch(getSubscriptionServiceApi());
  }, [dispatch]);

  const {
    getAvailableSubscriptionPlansData,
    isLoadingGetAvailableSubscriptionPlans,
  } = useAppSelector(getAvailableSubscriptionPlansSelector);
  const { getSubscriptionData, isLoadingGetSubscription } = useAppSelector(
    getSubscriptionSelector
  );

  const [subscriptionDetails, setSubscriptionDetails] =
    useState<SubscriptionDetails>({
      userName: '',
      userEmail: '',
      currentSubscriptionPlan: {} as currentSubscriptionType,
      userPlanCreatedDate: '',
      userPlanExpiryDate: '',
    });

  useEffect(() => {
    if (
      getSubscriptionData.length > 0 &&
      getAvailableSubscriptionPlansData.length > 0
    ) {
      const currentSubscriptionPlan = getAvailableSubscriptionPlansData.find(
        (availableSubscriptionPlan: currentSubscriptionType) => {
          return availableSubscriptionPlan.id === getSubscriptionData[0].planid;
        }
      );

      setSubscriptionDetails({
        ...subscriptionDetails,

        userName:
          sessionStorage.getItem('first_name') +
          ' ' +
          sessionStorage.getItem('last_name'),
        userEmail: sessionStorage.getItem('userName') ?? 'N/A',

        currentSubscriptionPlan: currentSubscriptionPlan,

        userPlanCreatedDate: new Date(
          getSubscriptionData[0]?.createddate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
        userPlanExpiryDate: new Date(
          getSubscriptionData[0]?.expirydate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      });
    }
  }, [getSubscriptionData, getAvailableSubscriptionPlansData]);

  const expirationDate = new Date(subscriptionDetails.userPlanExpiryDate);
  const currentDate = new Date();

  return (
    <Container
      id='currentplan'
      component='main'
      maxWidth='sm'
      sx={{
        ml: isMobile || isTablet ? 0 : 36.5,
        mt: isMobile || isTablet ? 0 : 15,
        height: '26.25rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
      }}
    >
      {/* Current Plan Heading */}
      {isLoadingGetAvailableSubscriptionPlans || isLoadingGetSubscription ? (
        <Loader />
      ) : (
        <div>
          <Typography
            sx={{
              mb: 1,
              color: 'var(--Body-Text-Light, #605E5E)',
              fontFamily: 'Poppins',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '-0.035rem',
            }}
            label='profile.CurrentPlan'
          >
            {subscriptionDetails.currentSubscriptionPlan ? (
              <>
                {subscriptionDetails.userPlanExpiryDate !== 'Lifetime' &&
                  (() => {
                    return expirationDate < currentDate ? (
                      <Typography
                        sx={{
                          color: 'red',
                          fontSize: '0.75rem',
                          marginLeft: 1,
                        }}
                        label='profile.PlanExpired'
                      />
                    ) : (
                      expirationDate.getTime() - currentDate.getTime() <
                        5 * 24 * 60 * 60 * 1000 && (
                        <Typography
                          sx={{
                            color: 'red',
                            fontSize: '0.75rem',
                            marginLeft: 1,
                          }}
                          label='profile.PlanExpiringSoon'
                        />
                      )
                    );
                  })()}
              </>
            ) : (
              <Typography
                sx={{
                  color: 'red',
                  fontSize: '0.75rem',
                  marginLeft: 1,
                }}
                label='profile.NoPlanSelected'
              />
            )}
          </Typography>
          {(!subscriptionDetails.currentSubscriptionPlan ||
            (subscriptionDetails.userPlanExpiryDate !== 'Lifetime' &&
              (expirationDate.getTime() - currentDate.getTime() <
                5 * 24 * 60 * 60 * 1000 ||
                expirationDate < currentDate))) && (
            <Button
              variant='contained'
              onClick={handleSubscriptionClick}
              label='profile.Upgrade'
              sx={{
                borderRadius: '5rem',
                background: '#1206F9',
                float: 'right',
                width: 'fit-content',

                mt: 1,
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '1.375rem',
                mb: 2,
              }}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: '#33344B',
                fontFamily: 'Poppins',
                fontSize: '1.25rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '1.375rem',
                mb: 1,
              }}
              label='profile.MemberDetails'
            />

            <div>
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                }}
                label='profile.Name'
              >
                : {subscriptionDetails.userName}
              </Typography>

              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                }}
                label='profile.Email'
              >
                : {subscriptionDetails.userEmail}
              </Typography>
            </div>
          </Box>
          <Divider
            sx={{
              width: '42vw',
              height: '0.063rem',
              background: 'rgba(0, 0, 0, 0.25)',
            }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: '#33344B',
                fontFamily: 'Poppins',
                fontSize: '1.25rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '1.375rem',
                mb: 1,
              }}
              label='profile.PlanDetails'
            />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                  mr: 1,
                }}
                label='profile.PlanName'
              />
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                }}
                label={
                  subscriptionDetails.currentSubscriptionPlan
                    ? subscriptionDetails.currentSubscriptionPlan.planname
                    : 'profile.NA'
                }
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                  mr: 1,
                }}
                label='profile.Status'
              />
              {subscriptionDetails.currentSubscriptionPlan ? (
                new Date(subscriptionDetails.userPlanExpiryDate) <
                new Date() ? (
                  <Typography sx={{ color: 'red' }} label='profile.Expired' />
                ) : (
                  <Typography sx={{ color: 'blue' }} label='profile.Active' />
                )
              ) : (
                <Typography sx={{ color: 'red' }} label='profile.NA' />
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                  mr: 1,
                }}
                label='profile.StartDate'
              />
              <Typography
                label={
                  subscriptionDetails.currentSubscriptionPlan
                    ? subscriptionDetails.userPlanCreatedDate
                    : 'profile.NA'
                }
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.625rem',
                  mr: 1,
                }}
                label='profile.EndDate'
              />
              <Typography
                label={
                  subscriptionDetails.currentSubscriptionPlan
                    ? subscriptionDetails.userPlanExpiryDate
                    : 'profile.NA'
                }
              />
            </div>
          </Box>
        </div>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, gap: 1 }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.313rem',
            letterSpacing: '-0.04em',
            textAlign: 'left',
            mt: 'auto', // Align to the bottom
            mb: 1,
          }}
          label='profile.ToManageSubscriptionGoTo'
        ></Typography>
        <Link
          to={ProtectedRoutes.subscriptionsRoute}
          style={{ color: '#5249FF', textDecoration: 'none' }}
        >
          <Typography
            sx={{
              color: '#5249FF',
              textDecoration: 'none',
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.313rem',
              letterSpacing: '-0.04em',
            }}
            label='profile.MembershipPlans'
          />
        </Link>
      </Box>
      <Divider
        sx={{ width: '46vw', height: '0.063rem', background: '#DFDFDF', mb: 1 }}
      />
    </Container>
  );
}

export default CurrentPlan;
