import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import {
  Exception_error_Summary,
  Processing_Status,
  Processing_is_in_progress,
  Summary_Status,
} from 'constants/videoPreview';
import React, { useState, useEffect, useMemo } from 'react';
import { CoachingSessionReport, SectionConfig } from 'types/VideoPreview-types';
import { Toast } from 'widgets/Toast/Toast';
import Typography from 'widgets/Typography/Typography';

const recoveryPlanKeys = {
  'Immediate Actions': 'immediate_actions',
  'Short-term Goals': 'short_term_goals',
  'Long-term Strategies': 'long_term_strategies',
  'Emotional Support': 'emotional_support',
  'Progress Monitoring': 'progress_monitoring',
} as const;

type RecoveryPlanKey = keyof typeof recoveryPlanKeys;

function MedicalSummary({
  ProcessDataListForParticipantsVersion2,
  isEditing,
  showOriginal,
  editedContent,
  setEditedContent,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  isEditing: boolean;
  showOriginal: boolean;
  editedContent: Record<string, string>;
  setEditedContent: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}) {
  const firstParticipant = ProcessDataListForParticipantsVersion2?.[0];
  const detailedSummaryLoad = firstParticipant?.detailed_summary;

  const isDetailedSummaryEmpty =
    detailedSummaryLoad && Object.keys(detailedSummaryLoad).length === 0;

  const sectionsConfig: SectionConfig[] = useMemo(() => {
    if (isDetailedSummaryEmpty && firstParticipant.process_status !== 13) {
      return [
        {
          label: `${Processing_Status}`,
          rows: 1,
          value: `${Processing_is_in_progress}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    if (isDetailedSummaryEmpty && firstParticipant.process_status === 13) {
      return [
        {
          label: `${Summary_Status}`,
          rows: 1,
          value: `${Exception_error_Summary}`,
          onChangeKey: 'status_message',
        },
      ];
    }

    return showOriginal
      ? [
          {
            label: 'Patient Overview',
            rows: 4,
            value:
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.patient_overview?.relevant_info || ''
              }\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.patient_overview?.medical_history || ''
              }\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.patient_overview?.current_health_status || ''
              }`,
            onChangeKey: 'patient_overview',
          },
          {
            label: 'Energy Levels',
            rows: 10,
            value:
              `Dominant Emotion: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.dominant_emotion || ''
              }\n` +
              `${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.energy_levels || ''
              }\n` +
              `Engagement Score: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.engagement_score || ''
              }\n` +
              `Confident Tone: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.confidence_fatigue_stress
                  ?.confident_tone || ''
              }\n` +
              `Fatigued Tone: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.confidence_fatigue_stress
                  ?.fatigued_tone || ''
              }\n` +
              `Stressed Tone: ${
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.overall_sentiment?.confidence_fatigue_stress
                  ?.stressed_tone || ''
              }`,
            onChangeKey: 'overall_sentiment',
          },
          {
            label: 'Emotional Discrepancies',
            rows: 4,
            value:
              ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                ?.detailed_tone_analysis?.emotional_discrepancies || '',
            onChangeKey: 'emotional_discrepancies',
          },
          {
            label: 'Stress Indicators',
            rows: 4,
            value: (
              ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                ?.detailed_tone_analysis?.stress_indicators || []
            ).join('\n'),
            onChangeKey: 'stress_indicators',
          },
          {
            label: 'Recovery Plan',
            rows: 20,
            value: Object.keys(recoveryPlanKeys)
              .map((sectionTitle) => {
                const key = recoveryPlanKeys[sectionTitle as RecoveryPlanKey];

                const items =
                  ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                    ?.recovery_plan?.[key] || [];

                return `${sectionTitle}:\n${items.join('\n')}\n`;
              })
              .join('\n'),
            onChangeKey: 'recovery_plan',
          },
          {
            label: 'Billable Code with Identified Services',
            rows: 4,
            value: (() => {
              const billableCodes =
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.medicaid_billable_events?.billable_codes || [];
              const identifiedServices =
                ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
                  ?.medicaid_billable_events?.identified_services || [];

              if (
                billableCodes.length === 0 ||
                identifiedServices.length === 0
              ) {
                return 'N/A';
              }

              return billableCodes
                .map((code, index) => {
                  const service = identifiedServices[index] || 'N/A';
                  return `${code} : ${service}`;
                })
                .join('\n');
            })(),
            onChangeKey: 'medicaid_billable_events',
          },
        ]
      : [
          {
            label: 'Patient Overview',
            rows: 4,
            value: `${
              firstParticipant?.detailed_summary_edited?.patient_overview || ''
            }\n`,
            onChangeKey: 'patient_overview',
          },
          {
            label: 'Energy Levels',
            rows: 10,
            value: `${
              firstParticipant?.detailed_summary_edited?.overall_sentiment || ''
            }\n`,
            onChangeKey: 'overall_sentiment',
          },
          {
            label: 'Emotional Discrepancies',
            rows: 4,
            value: `${
              firstParticipant?.detailed_summary_edited
                ?.emotional_discrepancies || ''
            }\n`,
            onChangeKey: 'emotional_discrepancies',
          },
          {
            label: 'Stress Indicators',
            rows: 4,
            value: `${
              firstParticipant?.detailed_summary_edited?.stress_indicators || ''
            }\n`,
            onChangeKey: 'stress_indicators',
          },
          {
            label: 'Recovery Plan',
            rows: 20,
            value: `${
              firstParticipant?.detailed_summary_edited?.recovery_plan || ''
            }\n`,
            onChangeKey: 'recovery_plan',
          },
          {
            label: 'Billable Code with Identified Services',
            rows: 4,
            value: `${
              firstParticipant?.detailed_summary_edited
                ?.medicaid_billable_events || 'N/A'
            }\n`,
            onChangeKey: 'medicaid_billable_events',
          },
        ];
  }, [showOriginal, ProcessDataListForParticipantsVersion2]);

  useEffect(() => {
    setEditedContent(
      sectionsConfig.reduce((acc, section) => {
        acc[section.onChangeKey] = section.value;
        return acc;
      }, {} as Record<string, string>)
    );
  }, [sectionsConfig]);

  const handleTextFieldChange = (key: string, newValue: string) => {
    setEditedContent((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  //   const handleCopy = () => {
  //     const allDataToCopy = sectionsConfig
  //       .map((section) => section.value)
  //       .join('\n\n');
  //     if (allDataToCopy) {
  //       navigator.clipboard.writeText(allDataToCopy);
  //       Toast('Content copied successfully!', 'success');
  //     } else {
  //       Toast('Failed to copy', 'error');
  //     }
  //   };

  return (
    <Box>
      {isEditing ? (
        <div>
          {sectionsConfig.map((section, index) => (
            <section key={index} style={{ marginTop: 2, marginBottom: 2 }}>
              <Typography label={section.label} sx={{ mt: 1, mb: 1 }} />
              <TextField
                multiline
                rows={section.rows}
                value={editedContent[section.onChangeKey]}
                onChange={(e) =>
                  handleTextFieldChange(section.onChangeKey, e.target.value)
                }
                fullWidth
                variant='outlined'
                sx={{ fontSize: '14px' }}
              />
            </section>
          ))}
        </div>
      ) : (
        <>
          {sectionsConfig.map((section, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '4px',
                p: 1,
                maxWidth: '100%',
              }}
            >
              <Typography label='' sx={{ fontWeight: 600, fontSize: '16px' }}>
                {section.label}
              </Typography>
              <Typography
                label=''
                sx={{
                  fontSize: '14px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  maxWidth: '100%',
                  display: 'block',
                }}
              >
                {section.value || 'N/A'}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}

export default MedicalSummary;
