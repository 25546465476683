import { createSlice, Action } from '@reduxjs/toolkit';
import store from 'redux/store';
import { setSectionUploadStatusServiceApi } from 'services/MyAssessment-service';
import {
  AllPromptsSectionsStatusInitialStateTypes,
  PractiseSectionsInitialStateTypes,
  UploadSectionVideoInitialStateTypes,
} from 'types/MyAssessments-types';
import {
  SessionTypesInitialStateTypes,
  UploadMediaData,
} from 'types/Upload-types';

const practiseSectionsInitialState: PractiseSectionsInitialStateTypes = {
  isLoadingPractiseSections: false,
  errorPractiseSections: '',
  practiseSections: [],
};

const getPractiseSectionsSlice = createSlice({
  name: 'getPractiseSectionsSlice',
  initialState: practiseSectionsInitialState,
  reducers: {
    getPractiseSectionsLoad(state) {
      state.isLoadingPractiseSections = true;
    },
    getPractiseSectionsSuccess(state, action) {
      state.isLoadingPractiseSections = false;
      state.practiseSections = action.payload;
    },
    getPractiseSectionsFailure(state, action) {
      state.isLoadingPractiseSections = false;
      state.errorPractiseSections = action.payload;
    },
  },
});

export const {
  getPractiseSectionsLoad,
  getPractiseSectionsSuccess,
  getPractiseSectionsFailure,
} = getPractiseSectionsSlice.actions;

export { getPractiseSectionsSlice };

const questionnairePractiseSectionsInitialState: SessionTypesInitialStateTypes =
  {
    isLoadingSessionTypes: false,
    errorSessionTypes: '',
    SessionTypes: [],
  };

const getQuestionnairePractiseSectionsSlice = createSlice({
  name: 'getPractiseSectionsSlice',
  initialState: questionnairePractiseSectionsInitialState,
  reducers: {
    getQuestionnairePractiseSectionsLoad(state) {
      state.isLoadingSessionTypes = true;
    },
    getQuestionnairePractiseSectionsSuccess(state, action) {
      state.isLoadingSessionTypes = false;
      state.SessionTypes = action.payload;
    },
    getQuestionnairePractiseSectionsFailure(state, action) {
      state.isLoadingSessionTypes = false;
      state.errorSessionTypes = action.payload;
    },
  },
});

export const {
  getQuestionnairePractiseSectionsLoad,
  getQuestionnairePractiseSectionsSuccess,
  getQuestionnairePractiseSectionsFailure,
} = getQuestionnairePractiseSectionsSlice.actions;

export { getQuestionnairePractiseSectionsSlice };

const uploadSectionVideoInitialState: UploadSectionVideoInitialStateTypes = {
  isUploadingSectionVideo: false,
  errorUploadSectionVideo: '',
  uploadSectionVideo: { Job_Id: -1 },
};

const uploadSectionVideoSlice = createSlice({
  name: 'uploadSectionVideoSlice',
  initialState: uploadSectionVideoInitialState,
  reducers: {
    uploadSectionVideoLoad(state) {
      state.isUploadingSectionVideo = true;
    },
    uploadSectionVideoSuccess(state, action) {
      state.isUploadingSectionVideo = false;
      state.uploadSectionVideo = action.payload;
    },
    uploadSectionVideoFailure(state, action) {
      state.isUploadingSectionVideo = false;
      state.errorUploadSectionVideo = action.payload;
    },
  },
});

export const {
  uploadSectionVideoLoad,
  uploadSectionVideoSuccess,
  uploadSectionVideoFailure,
} = uploadSectionVideoSlice.actions;

export { uploadSectionVideoSlice };

const allPromptsSectionsStatusInitialState: AllPromptsSectionsStatusInitialStateTypes =
  {
    isLoadingAllPromptsSectionsStatus: false,
    errorAllPromptsSectionsStatus: '',
    allPromptsSectionsStatusData: {
      message: '',
      sections: [],
    },
  };

const allPromptsSectionsStatusSlice = createSlice({
  name: 'allPromptsSectionsStatusSlice',
  initialState: allPromptsSectionsStatusInitialState,
  reducers: {
    allPromptsSectionsStatusLoad(state) {
      state.isLoadingAllPromptsSectionsStatus = true;
    },
    allPromptsSectionsStatusSuccess(state, action) {
      state.isLoadingAllPromptsSectionsStatus = false;
      state.allPromptsSectionsStatusData = action.payload;
    },
    allPromptsSectionsStatusFailure(state, action) {
      state.isLoadingAllPromptsSectionsStatus = false;
      state.errorAllPromptsSectionsStatus = action.payload;
    },
  },
});

export const {
  allPromptsSectionsStatusLoad,
  allPromptsSectionsStatusSuccess,
  allPromptsSectionsStatusFailure,
} = allPromptsSectionsStatusSlice.actions;

export { allPromptsSectionsStatusSlice };

const sectionUploadStatusInitialState: any = {
  uploadStatus: [],
  totalSeconds: 0,
  isLastVideo: false,
  related_to: -1,
  isFirstUpload: false,
  isLastVideoUploaded: false,
  videoTitle: '',
  isFirstReupload: false,
  isFirstUnskiped: false,
};

const sectionUploadStatusSlice = createSlice({
  name: 'sectionUploadStatusSlice',
  initialState: sectionUploadStatusInitialState,
  reducers: {
    sectionUploadSuccess(state, action) {
      const { sectionId, status, mediaBlobUrl, currentDataToUpload } =
        action.payload;

      const existingIndex = state.uploadStatus.findIndex(
        (item: any) => item.sectionId === sectionId
      );

      if (existingIndex !== -1) {
        state.uploadStatus[existingIndex].status = status;
        state.uploadStatus[existingIndex].mediaBlobUrl = mediaBlobUrl;
        state.uploadStatus[existingIndex].currentDataToUpload =
          currentDataToUpload;
      } else {
        state.uploadStatus.push({
          sectionId,
          status,
          mediaBlobUrl,
          currentDataToUpload,
        });
      }
    },
    setTotalDuration(state, action) {
      state.totalSeconds = action.payload;
    },
    setIsLastVideo(state, action) {
      state.isLastVideo = action.payload;
    },

    setRelatedToId(state, action) {
      state.related_to = action.payload;
    },
    setVideoTitle(state, action) {
      state.videoTitle = action.payload;
    },
    setIsFirstVideo(state, action) {
      state.isFirstUpload = action.payload;
    },
    clearUploadStatusArray(state) {
      state.uploadStatus = [];
    },
    setIsFirstReupload(state, action) {
      state.isFirstReupload = action.payload;
    },
    setIsFirstUnskipped(state, action) {
      state.isFirstUnskiped = action.payload;
    },
  },
});
export const {
  sectionUploadSuccess,
  clearUploadStatusArray,
  setTotalDuration,
  setIsLastVideo,
  setRelatedToId,
  setIsFirstVideo,
  setVideoTitle,
  setIsFirstReupload,
  setIsFirstUnskipped,
} = sectionUploadStatusSlice.actions;

export { sectionUploadStatusSlice };
