import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useState } from 'react';
import { Box, Chip, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

interface AddEmailComponentProps {
  handleAddCandidate: (email: string) => void;
  email: string;
  setEmail: Function;
  candidateEmailList: string[];
  setCandidateEmailList: React.Dispatch<React.SetStateAction<string[]>>;
}

const AddEmailComponent: React.FC<AddEmailComponentProps> = ({
  handleAddCandidate,
  email,
  setEmail,
  candidateEmailList,
  setCandidateEmailList,
}) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
    setError(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ';') {
      event.preventDefault();
      processEmails();
    }
  };

  const processEmails = () => {
    const emails = emailInput
      .split(/[\s,;]+/)
      .map((email) => email.trim())
      .filter((email) => email !== '');

    const validEmails = emails.filter((email) => validateEmail(email));
    const invalidEmails = emails.filter((email) => !validateEmail(email));

    const newValidEmails = validEmails.filter(
      (email) => !candidateEmailList.includes(email)
    );

    if (newValidEmails.length > 0) {
      newValidEmails.forEach((email) => handleAddCandidate(email));
      setEmailInput('');
    }

    setError(invalidEmails.length > 0);
  };

  const validateEmail = (input: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
  };

  const handleDeleteChip = (emailToDelete: string) => {
    setCandidateEmailList(
      candidateEmailList.filter((email) => email !== emailToDelete)
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: 2,
        p: 1,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        minHeight: 50,
      }}
    >
      {candidateEmailList.map((email, index) => (
        <Chip
          key={index}
          label={email}
          onDelete={() => handleDeleteChip(email)}
          color='primary'
          sx={{ m: 0.5 }}
        />
      ))}
      <TextField
        variant='standard'
        size='small'
        placeholder='Enter Email Id'
        sx={{
          flexGrow: 1,
          minWidth: 100,
          '& .MuiInputBase-input': { color: error ? 'red' : 'inherit' },
          '& .MuiInput-underline:before': { borderBottom: 'none !important' },
          '& .MuiInput-underline:after': { borderBottom: 'none !important' },
          '& .MuiInput-underline:hover:before': {
            borderBottom: 'none !important',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { border: 'none' },
            '&:hover fieldset': { border: 'none' },
            '&.Mui-focused fieldset': { border: 'none' },
          },
          '& .MuiInputBase-root': {
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
          },
        }}
        multiline
        value={emailInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        error={error}
        helperText={error ? t('SendAssessments.InvalidEmailAddress') : ''}
      />
    </Box>
  );
};

export default AddEmailComponent;
