import React from 'react';

interface QuestionnaireVideoIconProps {
  isSelected?: boolean;
}

const QuestionnaireAudioIcon: React.FC<QuestionnaireVideoIconProps> = ({
  isSelected = false,
}) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_6278_9405)'>
        <path
          d='M18.4381 9.71724V11.5568C18.4381 13.2643 17.7598 14.9019 16.5524 16.1093C15.3449 17.3167 13.7073 17.995 11.9998 17.995M11.9998 17.995C10.2923 17.995 8.65466 17.3167 7.44725 16.1093C6.23984 14.9019 5.56152 13.2643 5.56152 11.5568V9.71724M11.9998 17.995V21.674M8.32079 21.674H15.6788M11.9998 1.43945C11.268 1.43945 10.5662 1.73016 10.0487 2.24762C9.53125 2.76508 9.24054 3.46691 9.24054 4.19872V11.5568C9.24054 12.2886 9.53125 12.9904 10.0487 13.5078C10.5662 14.0253 11.268 14.316 11.9998 14.316C12.7316 14.316 13.4334 14.0253 13.9509 13.5078C14.4684 12.9904 14.7591 12.2886 14.7591 11.5568V4.19872C14.7591 3.46691 14.4684 2.76508 13.9509 2.24762C13.4334 1.73016 12.7316 1.43945 11.9998 1.43945Z'
          stroke={isSelected ? '#FFFFFF' : '#49454F'}
          stroke-width='1.47161'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6278_9405'>
          <rect
            width='22.0741'
            height='22.0741'
            fill={isSelected ? '#FFFFFF' : '#49454F'}
            transform='translate(0.962891 0.519531)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuestionnaireAudioIcon;
