/** @format */

import i18n from 'i18next';
import {
  ProcessDataListFailure,
  ProcessDataListLoad,
  ProcessDataListSuccess,
  ReprocessVideoFailure,
  ReprocessVideoLoad,
  ReprocessVideoSuccess,
  deleteProcessVideoFailure,
  deleteProcessVideoLoad,
  deleteProcessVideoSuccess,
} from 'redux/slices/Gallery-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { GetReprocessVideoServiceProps } from 'types/Gallery.types';
import { Toast } from 'widgets/Toast/Toast';
import {
  ProcessDataListVersion2Load,
  ProcessDataListVersion2Failure,
  ProcessDataListVersion2Success,
  deleteProcessVideoV2Load,
  deleteProcessVideoV2Success,
  deleteProcessVideoV2Failure,
} from 'redux/slices/GalleryVersion2-slice';
import {
  ReprocessVersion2VideoLoad,
  ReprocessVersion2VideoSuccess,
  ReprocessVersion2VideoFailure,
} from 'redux/slices/GalleryVersion2-slice';
import { decryptApiResponse } from 'shared/AesDecryption/AesDecryption';

export const GetProcessedDataListService = (): AppThunk => async (dispatch) => {
  dispatch(ProcessDataListLoad());

  await commonAPI
    .get(ProtectedUrls.getProcessedDataListService.url())
    .then((response) => {
      dispatch(ProcessDataListSuccess(response.data));
    })
    .catch((error) => {
      dispatch(
        ProcessDataListFailure(
          error ||
          i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    });
};

export const GetReprocessVideoService =
  (reprocessVideoData: GetReprocessVideoServiceProps): AppThunk =>
    async (dispatch) => {
      dispatch(ReprocessVideoLoad());

      await commonAPI
        .get(ProtectedUrls.getReprocessVideoService.url(reprocessVideoData))
        .then((response) => {
          dispatch(ReprocessVideoSuccess(response.data));
          Toast(
            i18n.t(
              'toastMessageNotifications.FileIsBeingReprocessedCheckBackAfterSometime'
            ),
            'success'
          );
          dispatch(getProcessDataListVersion2Service());
        })
        .catch((error) => {
          dispatch(
            ReprocessVideoFailure(
              error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
            )
          );
        });
    };

export const deleteProcessVideoService =
  (id: number): AppThunk =>
    async (dispatch) => {
      dispatch(deleteProcessVideoLoad());

      await commonAPI
        .get(ProtectedUrls.deleteProcessVideo.url(id))
        .then((response) => {
          dispatch(deleteProcessVideoSuccess(response.data));
          dispatch(getProcessDataListVersion2Service());
          Toast(
            i18n.t('toastMessageNotifications.FileDeletedSuccessfully'),
            'success'
          );
        })
        .catch((error) => {
          dispatch(
            deleteProcessVideoFailure(
              error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
            )
          );
        });
    };

export const getProcessDataListVersion2Service =
  (): AppThunk => async (dispatch) => {
    dispatch(ProcessDataListVersion2Load());

    await commonAPI
      .get(ProtectedUrls.getProcessedDataListVersion2Service.url())
      .then((response) => {
        if (response) {
          // const APIResponse = decryptApiResponse(response.data);
          const APIResponse = response.data;

          dispatch(ProcessDataListVersion2Success(APIResponse));
        }
      })
      .catch((error) => {
        dispatch(
          ProcessDataListVersion2Failure(
            error ||
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            )
          )
        );
      });
  };
export const GetReprocessVersion2VideoService =
  (
    reprocessVideoData: GetReprocessVideoServiceProps,
    handleOpenSuccessReprocessVideoModal: Function
  ): AppThunk =>
    async (dispatch) => {
      dispatch(ReprocessVersion2VideoLoad());

      await commonAPI
        .get(
          ProtectedUrls.getReprocessVideoVersion2VideoService.url(
            reprocessVideoData
          )
        )
        .then((response) => {
          dispatch(ReprocessVersion2VideoSuccess(response.data));
          handleOpenSuccessReprocessVideoModal();
          dispatch(getProcessDataListVersion2Service());
        })
        .catch((error) => {
          dispatch(
            ReprocessVersion2VideoFailure(
              error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
            )
          );
        });
    };

export const deleteProcessVideoV2Service =
  (id: number, handleOpenOnDeleteSuccessModal: Function): AppThunk =>
    async (dispatch) => {
      dispatch(deleteProcessVideoV2Load());

      await commonAPI
        .get(ProtectedUrls.deleteProcessVideoV2.url(id))
        .then((response) => {
          dispatch(deleteProcessVideoV2Success(response.data));
          dispatch(getProcessDataListVersion2Service());
          handleOpenOnDeleteSuccessModal();
        })
        .catch((error) => {
          dispatch(
            deleteProcessVideoV2Failure(
              error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
            )
          );
        });
    };

export const getProcessDataListForCandidatesServiceApi =
  (): AppThunk => async (dispatch) => {
    dispatch(ProcessDataListVersion2Load());

    await commonAPI
      .get(ProtectedUrls.getProcessedDataListForCandidates.url())
      .then((response) => {
        if (response) {
          // const APIResponse = decryptApiResponse(response.data);
          const APIResponse = response.data;

          dispatch(ProcessDataListVersion2Success(APIResponse));
        }
      })
      .catch((error) => {
        dispatch(
          ProcessDataListVersion2Failure(
            error ||
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            )
          )
        );
      });
  };
