import React from 'react';

function QuestionnaireVideoOffIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_6389_8480)'>
        <path
          d='M32 32V34C32 35.0609 31.5786 36.0783 30.8284 36.8284C30.0783 37.5786 29.0609 38 28 38H6C4.93913 38 3.92172 37.5786 3.17157 36.8284C2.42143 36.0783 2 35.0609 2 34V14C2 12.9391 2.42143 11.9217 3.17157 11.1716C3.92172 10.4214 4.93913 10 6 10H10M21.32 10H28C29.0609 10 30.0783 10.4214 30.8284 11.1716C31.5786 11.9217 32 12.9391 32 14V20.68L34 22.68L46 14V34M2 2L46 46'
          stroke='#FFFFFF'
          stroke-width='4'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6389_8480'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default QuestionnaireVideoOffIcon;
