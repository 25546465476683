export const marks = [
  {
    value: 75,
    label: '75',
    offset: 25,
    mark: true,
    style: {
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '-0.069px',
    },
  },
];

export const Processing_Status = 'Processing Status';
export const Processing_is_in_progress = 'Processing is in progress';
export const Summary_Status = 'Summary Status';
export const Exception_error_Summary =
  'Exception Occured while Processing please reprocess';
