import React from 'react';

interface QuestionnaireVideoIconProps {
  isSelected?: boolean;
}

const QuestionnaireTextIcon: React.FC<QuestionnaireVideoIconProps> = ({
  isSelected = false,
}) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.81685 19.6193C4.2619 19.6193 3.78682 19.4217 3.39162 19.0265C2.99643 18.6314 2.79883 18.1563 2.79883 17.6013V7.51119C2.79883 6.95623 2.99643 6.48116 3.39162 6.08596C3.78682 5.69076 4.2619 5.49316 4.81685 5.49316H20.9611C21.516 5.49316 21.9911 5.69076 22.3863 6.08596C22.7815 6.48116 22.9791 6.95623 22.9791 7.51119V17.6013C22.9791 18.1563 22.7815 18.6314 22.3863 19.0265C21.9911 19.4217 21.516 19.6193 20.9611 19.6193H4.81685ZM4.81685 17.6013H20.9611V7.51119H4.81685V17.6013ZM8.8529 16.5923H16.925V14.5743H8.8529V16.5923ZM5.82587 13.5653H7.84389V11.5472H5.82587V13.5653ZM8.8529 13.5653H10.8709V11.5472H8.8529V13.5653ZM11.8799 13.5653H13.898V11.5472H11.8799V13.5653ZM14.907 13.5653H16.925V11.5472H14.907V13.5653ZM17.934 13.5653H19.952V11.5472H17.934V13.5653ZM5.82587 10.5382H7.84389V8.5202H5.82587V10.5382ZM8.8529 10.5382H10.8709V8.5202H8.8529V10.5382ZM11.8799 10.5382H13.898V8.5202H11.8799V10.5382ZM14.907 10.5382H16.925V8.5202H14.907V10.5382ZM17.934 10.5382H19.952V8.5202H17.934V10.5382Z'
        fill={isSelected ? '#FFFFFF' : '#49454F'}
      />
    </svg>
  );
};

export default QuestionnaireTextIcon;
